const isProdEnv = process.env.REACT_APP_ENV === 'production';

const getBaseURL = (service) => {
  let domain = '';
  let env = '-staging';
  switch (process.env.REACT_APP_ENV) {
    default:
      break;
    case 'production':
      env = service === 'NEW_OO' ? '' : '-crm';
      break;
    case 'release':
      env = '-release';
  }

  switch (service) {
    case 'NEW_OO':
      domain = `https://homedeliverybackend${process.env.REACT_APP_ENV === 'release' ? '-release' : `${env}`}.mpaani.com`;
      break;
    case 'OO':
      domain = `https://apionlinedelivery${env}.mpaani.com`;
      break;
    case 'LEGACY':
      domain = `https://${isProdEnv ? 'api'
        : 'apionlinedelivery-staging'}.mpaani.com`;
      break;
    case 'USER':
      domain = 'https://saggregation.mpaani.com';
      break;
    case 'IMAGE':
      domain = isProdEnv
        ? 'https://apianalytics.mpaani.com'
        : 'https://saggregation.mpaani.com';
      break;
    default:
      break;
  }
  return domain;
};

export default getBaseURL;
