import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { reconcile, razorpayxmeta } from '../../assets/api/axios';
import {
  CustomTable, DatePicker, CustomModal, CustomDropdown,
  Svg,
} from '../../component/common';
import { Constant, Utils } from '../../utilities';
import { dateString } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, createdOnFrom, createdOnTo,
  } = param;
  const {
    storeIds = '',
    reconcilationIds = '',
    orderIds = '',
    isPaid = '',
    isReversal = '',
    fraudFlag = '',
    isFraudStore = '',
    writeOff = '',
    onHold = '',
    hasFundAccount = '',
    hasRejectedDueToBank = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  createdOnFrom = Number(createdOnFrom) || '';
  createdOnTo = Number(createdOnTo) || '';
  return ({
    ...param,
    l,
    p,
    storeIds,
    reconcilationIds,
    orderIds,
    createdOnFrom,
    createdOnTo,
    isPaid,
    isReversal,
    fraudFlag,
    isFraudStore,
    writeOff,
    onHold,
    hasFundAccount,
    hasRejectedDueToBank,
  });
};

class ReconciledSettlements extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = props;
    this.canIEdit = userPermission
      && userPermission.includes(permission.RECONCILED_SETTLEMENTS_WRITE);
    this.state = {
      settlements: [],
      settlementMeta: null,
      settlementCount: 0,
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      storeIds: param.storeIds,
      reconcilationIds: param.reconcilationIds,
      orderIds: param.orderIds,
      hasFundAccount: 'true',
      fraudFlag: 'NOT_FLAGGED',
      loading: true,
      error: false,
      selectedSettlementToPay: null,
      comment: '',
      transactionId: '',
      paymentMode: 'CASH',
      submitting: false,
      submitError: false,
      submitErrorMsg: '',
      param,
      showDetails: false,
      reconcileDetail: null,
      selectSettlement: '',
      settlementIds: [],
      markingSettlementsPaid: false,
      markingSettlementsPaidModal: false,
      exportStatus: '',
      razorpayxBalance: '',
      status: '',
      viewSettlementMetaModal: false,
      settlementMetaData: null,
      settlementMetaDataLoadStatus: '',
      selectedSettlementToPerformAction: null,
      showPerformingActionModal: false,
      mode: 'DISCOUNT_AMOUNT',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  async handleAction() {
    const { settlementIds: ids, comment, paymentMode } = this.state;
    for (let i = 0; i < ids.length; i += 1) {
      ids[i].statusMessage = 'PROCESSING';
      ids[i].statusTextColor = 'text-warning';
      this.setState({
        settlementIds: ids,
      });
      // eslint-disable-next-line no-await-in-loop
      await reconcile(
        'PATCH',
        {
          action: 'PAY_SETTLEMENTS',
          body: {
            ...ids[i],
            comment,
            paymentMode,
          },
        },
        ids[i].reconciledId,
      ).then((res) => {
        if (res.status === 200) {
          const { settlements: newSettlements } = this.state;
          const index = newSettlements.findIndex((item) => (
            item.reconciledId === ids[i].reconciledId
          ));
          if (index !== -1) {
            newSettlements[index] = {
              ...newSettlements[index],
              ...res.data.data,
            };
          }
          ids[i].statusMessage = 'SUCCESS';
          ids[i].statusTextColor = 'text-green';
          this.setState({
            settlementIds: ids,
            settlements: newSettlements,
            markingSettlementsPaid: false,
            paymentMode: 'CASH',
          });
        } else {
          throw new Error();
        }
      }).catch((error) => {
        ids[i].statusMessage = error.response
          ? `Error: ${JSON.stringify(error.response.data.errors[0].message)}`
          : 'Error';
        ids[i].statusTextColor = 'text-danger';
        this.setState({
          settlementIds: ids,
          markingSettlementsPaid: false,
        });
      });
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleCustomDropdownChange = (data) => {
    this.setState({
      ...data,
    }, () => this.handleRequestProcessing());
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitSearchText = (e) => {
    const { name, value } = e.target;
    const { param } = this.state;
    if (value !== param[name]) {
      this.handleRequestProcessing({ [name]: value, p: 1 });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history, userPermission } = this.props;
    const { pathname } = history.location;
    const { param, fraudFlag, hasFundAccount } = this.state;
    const newParam = {
      ...param, ...data, fraudFlag, hasFundAccount,
    };
    const {
      l, p, storeIds, createdOnFrom, createdOnTo, isPaid, isReversal,
      isFraudStore, writeOff, onHold,
      hasRejectedDueToBank, reconcilationIds, orderIds,
    } = newParam;
    reconcile(
      'GET',
      null,
      null,
      this.source.token,
      {
        page: p,
        limit: l,
        storeIds,
        ids: reconcilationIds,
        orderId: orderIds,
        createdOnFrom,
        createdOnTo,
        isPaid,
        isReversal,
        fraudFlag,
        isFraudStore,
        isWriteOff: writeOff,
        isOnHold: onHold,
        hasFundAccount,
        hasRejectedDueToBank,
      },
    ).then((res) => {
      this.setState({
        settlements: res.data.data,
        settlementMeta: res.data.meta,
        loading: false,
        settlementCount: res.data.count,
        hasNext: res.data.next !== '',
        hasPrevious: res.data.prev !== '',
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        markingSettlementsPaid: false,
        selectSettlement: '',
        settlementIds: [],
        storeIds: newParam.storeIds,
        reconcilationIds: newParam.reconcilationIds,
        orderIds: newParam.orderIds,
        fraudFlag,
        hasFundAccount,
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    if (userPermission.includes(permission.RECONCILED_SETTLEMENTS_WRITE)) {
      this.getrazorpayxmeta();
    }

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  getrazorpayxmeta = (retryCount = 3) => {
    if (retryCount) {
      razorpayxmeta(
        'GET',
      ).then((res) => {
        this.setState({
          razorpayxBalance: res.data.razorpayx_balance,
          status: res.data.status,
        });
      }).catch(() => {
        this.getrazorpayxmeta(retryCount - 1);
      });
    }
  }

  handleExportAllSettlements = () => {
    const { param } = this.state;
    const {
      createdOnFrom, createdOnTo, storeIds, isPaid, isReversal,
      writeOff, onHold, isFraudStore, fraudFlag,
      hasFundAccount, hasRejectedDueToBank, reconcilationIds,
      orderIds,
    } = param;
    this.setState({
      exportStatus: 'loading',
    });
    reconcile(
      'GET',
      null,
      null,
      null,
      {
        page: '1',
        limit: 9999999,
        storeIds,
        ids: reconcilationIds,
        orderId: orderIds,
        createdOnFrom,
        createdOnTo,
        isPaid,
        isReversal,
        isFraudStore,
        fraudFlag,
        generateExport: true,
        isWriteOff: writeOff,
        isOnHold: onHold,
        hasFundAccount,
        hasRejectedDueToBank,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          exportStatus: 'success',
        }, () => {
          setTimeout(() => {
            this.setState({
              exportStatus: '',
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        exportStatus: 'error',
      });
    });
  }

  handleDateChange = (createdOnFrom, createdOnTo) => {
    const { param } = this.state;
    if (createdOnFrom !== param.createdOnFrom
      || createdOnTo !== param.createdOnTo) {
      this.handleRequestProcessing({
        createdOnFrom,
        createdOnTo,
        p: 1,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  performAction = () => {
    this.setState({
      submitting: true,
      submitError: false,
    });
    const {
      selectedSettlementToPerformAction, comment, mode, action,
    } = this.state;
    reconcile(
      'PATCH',
      {
        action,
        body: {
          comment,
          ...action === 'MARK_WRITE_OFF' ? { mode } : {},
        },
      },
      selectedSettlementToPerformAction.reconciledId,
    ).then((res) => {
      if (res) {
        const { settlements: newSettlements } = this.state;
        const index = newSettlements.findIndex((item) => (
          item.reconciledId === selectedSettlementToPerformAction.reconciledId
        ));
        newSettlements[index] = {
          ...newSettlements[index],
          ...res.data.data,
        };
        this.setState({
          submitting: false,
          selectedSettlementToPerformAction: null,
          showPerformingActionModal: false,
          comment: '',
          mode: 'DISCOUNT_AMOUNT',
          settlements: newSettlements,
          action: '',
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        submitError: true,
        submitting: false,
      });
    });
  }

  handleMarkStatusPaid = () => {
    this.setState({
      submitting: true,
      submitError: false,
    });
    const {
      selectedSettlementToPay, comment, transactionId, paymentMode,
    } = this.state;
    reconcile(
      'PATCH',
      {
        action: 'PAY_SETTLEMENTS',
        body: {
          ...selectedSettlementToPay,
          comment,
          transactionId,
          paymentMode,
        },
      },
      selectedSettlementToPay.reconciledId,
    ).then((res) => {
      if (res.status === 200) {
        const { settlements: newSettlements } = this.state;
        const index = newSettlements.findIndex((item) => (
          item.reconciledId === selectedSettlementToPay.reconciledId
        ));
        newSettlements[index] = {
          ...newSettlements[index],
          ...res.data.data,
        };
        this.setState({
          submitting: false,
          selectedSettlementToPay: null,
          comment: '',
          transactionId: '',
          paymentMode: 'CASH',
          settlements: newSettlements,
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0].message
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.errors[0].message,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong.',
      });
    });
  }

  getDetails = (reconcileId) => {
    this.setState({
      showDetails: true,
    });
    reconcile(
      'GET',
      null,
      reconcileId,
    ).then((res) => {
      this.setState({
        reconcileDetail: res.data.data,
      });
    }).catch(() => {});
  }

  handleSelectSettlement = (data) => {
    const { selectSettlement } = data;
    const { settlements } = this.state;
    let newSettlementIds = [];
    if (selectSettlement === 'all') {
      newSettlementIds = settlements.filter((item) => !item.isPaid
        && item.totalAmount >= item.totalRecoveryAmount
        && item.totalAmount > 0
        && item.bankDetailsVerified).map(
        (s) => ({
          ...s,
          statusMessage: 'NOT INITIATED',
          statusTextColor: '',
        }),
      );
    }
    this.setState({
      settlementIds: newSettlementIds,
      selectSettlement,
    });
  }

  handleSettlementSelection = (e, s) => {
    const { checked } = e.target;
    const { settlementIds } = this.state;
    let newSettlementIds = settlementIds;

    if (checked) {
      newSettlementIds.push(
        {
          ...s,
          statusMessage: 'NOT INITIATED',
          statusTextColor: '',
        },
      );
      this.setState({
        settlementIds: newSettlementIds,
      });
    } else {
      newSettlementIds = newSettlementIds.filter(
        (obj) => obj.reconciledId !== s.reconciledId,
      );
      this.setState({
        settlementIds: newSettlementIds,
      });
    }
  }

  loadSettlementMeta = (reconciledId) => {
    reconcile(
      'GET',
      null,
      reconciledId,
      null,
      {
        viewType: 'META',
      },
    ).then((res) => {
      this.setState({
        settlementMetaDataLoadStatus: '',
        settlementMetaData: res.data.data,
      });
    }).catch(() => {
      this.setState({
        settlementMetaDataLoadStatus: 'error',
      });
    });
    this.retry = () => {
      this.setState({
        settlementMetaDataLoadStatus: 'loading',
      }, () => {
        this.loadSettlementMeta(reconciledId);
      });
    };
  }

  render() {
    const {
      param, loading, error, settlements, storeIds,
      rowsPerPage, page, selectedSettlementToPay,
      comment, transactionId, submitting, submitError,
      settlementCount, hasNext, hasPrevious, paymentMode,
      showDetails, reconcileDetail, selectSettlement,
      settlementIds, markingSettlementsPaid,
      markingSettlementsPaidModal,
      exportStatus, razorpayxBalance, status, settlementMetaData,
      viewSettlementMetaModal, settlementMetaDataLoadStatus,
      mode, action, showPerformingActionModal, selectedSettlementToPerformAction,
      submitErrorMsg, reconcilationIds, fraudFlag, hasFundAccount,
      orderIds, settlementMeta,
    } = this.state;

    const { isDetailPage, userPermission, history } = this.props;

    let headers = [
      {
        key: 'reconciledId',
        displayText: 'Id',
        renderer: (data) => (
          <Button
            variant="link"
            className="fs-01 p-0"
            onClick={() => {
              this.getDetails(data.reconciledId);
            }}
          >
            <span>{data.reconciledId}</span>
          </Button>
        ),
      },
      {
        key: 'storeCode',
        displayText: 'Store Code',
        renderer: (data) => (
          <Button
            variant="link"
            className="fs-01 p-0"
            onClick={() => {
              history.push(`/retailer/${data.storeCode}?view=onboarding`);
            }}
          >
            <span>{data.storeCode}</span>
            {
              data.isFraudStore && (
                <span className="text-danger">
                  &nbsp;
                  (Fraud)
                </span>
              )
            }
          </Button>
        ),
      },
      {
        key: 'retailerAccountType',
        displayText: 'Retailer Account Type',
      },
      // {
      //   key: 'orderId',
      //   displayText: "Order Id's",
      //   renderer: (data) => (
      // eslint-disable-next-line max-len
      //     <div style={{ maxWidth: 200, whiteSpace: 'break-spaces' }}>{data.orderId.split(',').join('\n')}</div>
      //   ),
      // },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'createdByAdminUser',
        displayText: 'Created By',
      },
      {
        key: 'totalDiscountAmount',
        displayText: 'Total Disc. Amt.',
      },
      {
        key: 'totalDigitalAmount',
        displayText: 'Total Digital Amt.',
      },
      {
        key: 'totalRecoveryAmount',
        displayText: 'Total Recovery Amt.',
      },
      {
        key: 'totalWriteoffAmount',
        displayText: 'Total Write Off Amt.',
      },
      {
        key: 'totalAmount',
        displayText: 'Total Amount',
      },
      {
        key: 'commission',
        displayText: 'Commission',
      },
      {
        key: 'amountPaid',
        displayText: 'Amount Paid',
      },
      {
        key: 'razorPayFeesCharges',
        displayText: 'Transfer Fee',
      },
      {
        key: 'isPaid',
        displayText: 'Paid',
        renderer: (data) => (
          <div
            className={data.isPaid ? 'text-green' : 'text-danger'}
          >
            {data.isPaid ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'paidByAdminUser',
        displayText: 'Paid By',
      },
      ...!isDetailPage && this.canIEdit
        ? [{
          key: 'markPaid',
          displayText: 'Mark Paid',
          renderer: (data) => (
            <Button
              variant="outline-primary"
              className="px-2 py-1 fs-01"
              onClick={() => {
                this.setState({
                  selectedSettlementToPay: data,
                });
              }}
              disabled={
                !userPermission.includes(permission.PAY_SETTLEMENTS)
                || (data.isPaid || data.totalAmount < 0)
                || !!selectSettlement
                || !data.bankDetailsVerified
              }
            >
              Mark Paid
            </Button>
          ),
        }] : [],
      ...!isDetailPage ? [
        {
          key: 'reason',
          displayText: 'Reason',
          renderer: (data) => (
            <span>
              {data.bankDetailsVerified ? '--' : 'Bank Details not verified'}
            </span>
          ),
        },
      ] : [],
      {
        key: 'isPaymentTransferred',
        displayText: 'Pay. Transfd',
        renderer: (data) => (
          <div
            className={data.isPaymentTransferred ? 'text-green' : 'text-danger'}
          >
            {data.isPaymentTransferred ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'comment',
        displayText: 'Comment',
      },
      {
        key: 'isReversal',
        displayText: 'Reversal',
        renderer: (data) => (
          <div
            className={data.isReversal ? 'text-danger' : 'text-green'}
          >
            {data.isReversal ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'isWriteOffed',
        displayText: 'Write Offed',
        renderer: (data) => (
          <div
            className={data.isWriteOffed ? 'text-danger' : 'text-green'}
          >
            {data.isWriteOffed ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'lastUpdatedBy',
        displayText: 'Last Updated By',
      },
      {
        key: 'lastReversalAt',
        displayText: 'Last Reverasal At',
        renderer: (data) => (
          <>{dateString(data.lastReversalAt)}</>
        ),
      },
      {
        key: 'meta',
        displayText: 'View Meta',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                settlementMetaData: null,
                viewSettlementMetaModal: true,
                settlementMetaDataLoadStatus: 'loading',
              }, this.loadSettlementMeta(data.reconciledId));
            }}
            className="fs-01 p-0"
          >
            META
          </Button>
        ),
      },
      ...this.canIEdit
        ? [{
          key: 'other-action',
          displayText: 'Other Action',
          renderer: (data) => {
            if ((!data.status || data.status === 'PENDING' || data.status === 'ON_HOLD') && !data.isPaid) {
              return (
                <select
                  className="form-control fs-01 minw-120p"
                  onChange={(event) => {
                    if (!event.target.value) {
                      return;
                    }
                    this.setState({
                      selectedSettlementToPerformAction: data,
                      showPerformingActionModal: true,
                      action: event.target.value,
                    });
                  }}
                  value={
                  selectedSettlementToPerformAction
                  && selectedSettlementToPerformAction.reconciledId === data.reconciledId
                    ? action : ''
                }
                >
                  <option value="">--SELECT ACTION--</option>
                  <option value="MARK_WRITE_OFF">MARK_WRITE_OFF</option>
                  <option value="MARK_ON_HOLD">MARK_ON_HOLD</option>
                </select>
              );
            }
            return (<div className="text-center">--</div>);
          },
        }] : [],
    ];

    let totalAmount = 0;
    let totalCommission = 0;
    let totalReconcile = 0;
    if (settlementIds.length) {
      settlementIds.forEach((item) => {
        totalAmount += item.totalAmount;
        totalCommission += item.commission;
      });
      totalReconcile = totalAmount - totalCommission;
    }

    if (selectSettlement) {
      headers = [
        {
          key: 'action-dropdown',
          displayText: (
            <CustomDropdown
              item={{
                key: 'action',
                displayText: 'Action',
                options: [
                  {
                    label: 'Mark Paid',
                    value: 'MARK_PAID',
                  },
                ],
              }}
              onChange={() => {
                if (settlementIds.length) {
                  this.setState({
                    markingSettlementsPaidModal: true,
                  });
                }
              }}
              selectedVal=""
              disabled={markingSettlementsPaid}
            />
          ),
          renderer: (data) => {
            const settlement = settlementIds.find((item) => (
              item.reconciledId === data.reconciledId
            ));
            if (!settlement
              && (
                data.isPaid
                || data.totalAmount < data.totalRecoveryAmount
                || data.totalAmount <= 0
                || !data.bankDetailsVerified
                || !this.canIEdit
              )
            ) {
              return '';
            }
            if (!!settlement && settlement.statusMessage === 'SUCCESS') {
              return (
                <Svg
                  svg="circleDone"
                  width="15px"
                  fill={Constant.Color.GREEN}
                />
              );
            }
            return (
              <Form.Check
                custom
                id={`${data.reconciledId}-checkbox`}
                label=""
                value={data.reconciledId}
                checked={!!settlement}
                onChange={(e) => this.handleSettlementSelection(e, data)}
              />
            );
          },
        },
        {
          key: 'action-status',
          displayText: 'Marking Paid Status',
          renderer: (data) => {
            const obj = settlementIds.find((s) => s.reconciledId === data.reconciledId);
            if (obj) {
              return (
                <div
                  className={obj.statusTextColor}
                >
                  {obj.statusMessage}
                </div>
              );
            }
            return '';
          },
        },
      ].concat(headers);
    }

    const reconcileDetailsHeaders = [
      {
        key: 'orderId',
        displayText: 'Order Id',
      },
      {
        key: 'createdOn',
        displayText: 'Created',
        renderer: (data) => Utils.dateString(data.createdOn),
      },
      {
        key: 'paymentMode',
        displayText: 'Payment Mode',
      },
      {
        key: 'totalAmount',
        displayText: 'Total Amount',
      },
      {
        key: 'amountPayable',
        displayText: 'Digital Payment',
      },
      {
        key: 'discountAmount',
        displayText: 'Discount',
      },
      {
        key: 'commission',
        displayText: 'Commission',
      },
      {
        key: 'amountPaid',
        displayText: 'Amount Paid',
      },
    ];

    const getMetaData = (data) => (
      <Row
        className="mx-2 py-1"
      >
        {data.map((item) => (
          <Col
            key={item.header}
            className="px-2 py-1 text-medium"
          >
            <div
              className="px-4 py-2 fs-02 h-100 meta-data"
            >
              <div>
                {item.header}
              </div>
              <div
                className="font-weight-bold top-align-text"
              >
                {`${item.content}` || '---'}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );

    const filtersConfig = [
      {
        key: 'isPaid',
        displayText: 'Paid',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isReversal',
        displayText: 'Reversal',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isFraudStore',
        displayText: 'fraud store',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'writeOff',
        displayText: 'WRITE_OFF',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'onHold',
        displayText: 'ON_HOLD',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'hasRejectedDueToBank',
        displayText: 'Rejected Due To Bank Account',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
                Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                  Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={showDetails}
          closeButton
          onHide={() => {
            this.setState({
              showDetails: false,
              reconcileDetail: null,
            });
          }}
          title="Reconciliation details"
          size="lg"
          body={(
            <Container>
              {reconcileDetail && (
                <>
                  <Row>
                    {getMetaData([
                      {
                        header: 'Id',
                        content: reconcileDetail.reconciledId,
                      },
                      {
                        header: 'Paid',
                        content: reconcileDetail.isPaid ? 'Yes' : 'No',
                      },
                      {
                        header: 'Store',
                        content: reconcileDetail.storeCode,
                      },
                      {
                        header: 'Total',
                        content: reconcileDetail.totalAmount,
                      },
                      {
                        header: 'Commission',
                        content: reconcileDetail.commission,
                      },
                      {
                        header: 'Amt.Paid',
                        content: reconcileDetail.amountPaid,
                      },
                    ])}
                  </Row>
                  <CustomTable
                    headers={reconcileDetailsHeaders}
                    content={reconcileDetail.settlements}
                    isPaginated={false}
                    keyField="id"
                    totalItems={reconcileDetail.settlements.length}
                  />
                </>
              )}
            </Container>
          )}
        />
        <CustomModal
          show={viewSettlementMetaModal}
          onHide={() => {
            this.setState({
              viewSettlementMetaModal: false,
              settlementMetaData: null,
            });
          }}
          title="Settlement Meta Data"
          closeButton
          size="lg"
          body={(
            <Container>
              {
                settlementMetaDataLoadStatus === 'loading' && (
                  <div
                    className="pt-3 text-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )
              }
              {
                settlementMetaDataLoadStatus === 'error' && (
                  <div
                    className="pt-3 text-center text-danger"
                  >
                    Something Went Wrong
                    <Button
                      variant="primary"
                      onClick={() => this.retry()}
                      className="ml-3"
                    >
                      Retry
                    </Button>
                  </div>
                )
              }
              {
                settlementMetaData && settlementMetaData.map((item) => (
                  <Row
                    key={item.key}
                  >
                    <Col xs={12} className="border py-1">
                      {item.key}
                    </Col>
                    <Col xs={12} className="border py-1">
                      {item.value}
                    </Col>
                  </Row>
                ))
              }
            </Container>
          )}
        />
        <CustomModal
          show={!!selectedSettlementToPay}
          closeButton
          onHide={() => {
            this.setState({
              selectedSettlementToPay: null,
              comment: '',
              transactionId: '',
              paymentMode: 'CASH',
            });
          }}
          title="Mark Settlement Paid"
          body={(
            <Container>
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Comment:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="comment"
                    value={comment}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Payment Mode:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <select
                    name="paymentMode"
                    id="paymentMode"
                    className="form-control"
                    value={paymentMode}
                    onChange={this.handleChange}
                  >
                    <option value="CASH">CASH</option>
                    <option value="DIGITAL">DIGITAL</option>
                    <option value="RAZORPAY">PAY VIA RAZORPAY</option>
                    <option value="RAZORPAYX">PAY VIA RAZORPAYX</option>
                    <option value="CITI">PAY VIA CITIBANK</option>
                  </select>
                </Col>
              </Row>
              {paymentMode === 'DIGITAL' && (
                <Row
                  className="pt-3"
                >
                  <Col
                    xs={8}
                  >
                    <b>Transaction Id:</b>
                  </Col>
                  <Col
                    className="px-3"
                    xs={16}
                  >
                    <input
                      type="number"
                      className="form-control"
                      name="transactionId"
                      value={transactionId}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-3"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2"
                    disabled={
                      !comment
                      || ((paymentMode === 'DIGITAL') && !transactionId)
                      || submitting
                    }
                    onClick={this.handleMarkStatusPaid}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
                {submitError && (
                  <Col
                    xs={24}
                    className="text-center text-danger pt-3"
                  >
                    <b>{submitErrorMsg || 'Oops Something went Wrong!!'}</b>
                  </Col>
                )}
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={markingSettlementsPaidModal}
          closeButton
          onHide={() => {
            this.setState({
              markingSettlementsPaidModal: false,
              comment: '',
              paymentMode: 'CASH',
            });
          }}
          title="Mark Settlement Paid"
          body={(
            <Container>
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Comment:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="comment"
                    value={comment}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Payment Mode:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <select
                    name="paymentMode"
                    id="paymentMode"
                    className="form-control"
                    value={paymentMode}
                    onChange={this.handleChange}
                  >
                    <option value="CASH">CASH</option>
                    <option value="RAZORPAY">PAY VIA RAZORPAY</option>
                    <option value="RAZORPAYX">PAY VIA RAZORPAYX</option>
                    <option value="CITI">PAY VIA CITIBANK</option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-3"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2"
                    disabled={!paymentMode}
                    onClick={() => {
                      this.setState({
                        markingSettlementsPaidModal: false,
                        markingSettlementsPaid: true,
                      }, () => {
                        this.handleAction();
                      });
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={showPerformingActionModal}
          title="Perform Action On Settlement"
          onHide={() => {
            this.setState({
              showPerformingActionModal: false,
              selectedSettlementToPerformAction: null,
              submitError: false,
              mode: 'DISCOUNT_AMOUNT',
              comment: '',
              action: '',
            });
          }}
          closeButton
          body={(
            <Container>
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Comment:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="comment"
                    value={comment}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              {
                action === 'MARK_WRITE_OFF' && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Mode:</b>
                    </Col>
                    <Col
                      className="px-3"
                      xs={16}
                    >
                      <select
                        name="mode"
                        id="mode"
                        className="form-control"
                        value={mode}
                        onChange={this.handleChange}
                      >
                        <option value="DISCOUNT_AMOUNT">DISCOUNT_AMOUNT</option>
                        <option value="COMPLETE">COMPLETE</option>
                      </select>
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-3"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2"
                    disabled={
                      !comment
                      || submitting
                    }
                    onClick={this.performAction}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
                {submitError && (
                  <Col
                    xs={24}
                    className="text-center text-danger pt-3"
                  >
                    <b>Oops Something went Wrong!!</b>
                  </Col>
                )}
              </Row>
            </Container>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center"
            >
              <Col
                xs="auto"
                className="px-2 py-2"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    placeholder="Store Ids"
                    name="storeIds"
                    className="fs-01 rounded-0"
                    value={storeIds}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText(e);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="px-2 py-2"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    placeholder="Reconcilation Ids"
                    name="reconcilationIds"
                    className="fs-01 rounded-0"
                    value={reconcilationIds}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText(e);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="px-2 py-2"
              >
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className="rounded-0"
                    >
                      <Svg
                        svg="search"
                        width="1rem"
                        fill={Constant.Color.DARK}
                      />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    placeholder="Order Ids"
                    name="orderIds"
                    className="fs-01 rounded-0"
                    value={orderIds}
                    onChange={this.handleOnChange}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        this.onSubmitSearchText(e);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col
                xs="auto"
                className="px-2 py-2"
              >
                <DatePicker
                  isDateRange
                  onApply={(dateRange) => {
                    this.handleDateChange(
                      new Date(dateRange.startDate).getTime(),
                      new Date(dateRange.endDate).getTime(),
                    );
                  }}
                  startDate={param.createdOnFrom}
                  endDate={param.createdOnTo}
                  onClear={() => {
                    this.handleRequestProcessing({
                      createdOnFrom: '', createdOnTo: '',
                    });
                  }}
                />
              </Col>
              {
                filtersConfig.map((item) => (
                  <Col
                    xs="auto"
                    className="px-2 py-2"
                    key={item.key}
                  >
                    <CustomDropdown
                      item={item}
                      closeButton
                      onChange={this.handleDropdownChange}
                      selectedVal={param[item.key]}
                    />
                  </Col>
                ))
              }
              <CustomDropdown
                item={{
                  key: 'hasFundAccount',
                  displayText: 'Fund Account',
                  options: [
                    {
                      label: 'True',
                      value: 'true',
                    },
                    {
                      label: 'False',
                      value: 'false',
                    },
                    {
                      label: 'None',
                      value: '',
                    },
                  ],
                }}
                closeButton
                onChange={this.handleCustomDropdownChange}
                selectedVal={hasFundAccount}
              />
              <CustomDropdown
                item={{
                  key: 'fraudFlag',
                  displayText: 'Fraud Flags',
                  options: [
                    {
                      label: 'FRAUD',
                      value: 'FRAUD',
                    },
                    {
                      label: 'UNDER_REVIEW',
                      value: 'UNDER_REVIEW',
                    },
                    {
                      label: 'FLAGGED',
                      value: 'FLAGGED',
                    },
                    {
                      label: 'NOT_FLAGGED',
                      value: 'NOT_FLAGGED',
                    },
                    {
                      label: 'ALL',
                      value: 'all',
                    },
                  ],
                }}
                closeButton
                onChange={this.handleCustomDropdownChange}
                selectedVal={fraudFlag}
              />

              {!isDetailPage && this.canIEdit && (
              <Col
                xs="auto"
                className="px-2 py-2"
              >
                <CustomDropdown
                  item={{
                    key: 'selectSettlement',
                    displayText: 'Select Settlement',
                    options: [
                      {
                        label: 'All',
                        value: 'all',
                      },
                      {
                        label: 'Manually',
                        value: 'manually',
                      },
                      {
                        label: 'None',
                        value: '',
                      },
                    ],
                  }}
                  onChange={this.handleSelectSettlement}
                  selectedVal={selectSettlement}
                  closeButton={false}
                  disabled={
                    !userPermission.includes(permission.PAY_SETTLEMENTS)
                    || markingSettlementsPaid
                  }
                />
              </Col>
              )}
              <Col
                xs="auto"
                className="p-2 d-flex align-items-center justify-content-center"
              >
                {
                  exportStatus === 'loading'
                    ? (
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    ) : (
                      <Button
                        className="px-2 fs-01 py-1 rounded-0"
                        onClick={this.handleExportAllSettlements}
                        variant={exportStatus === 'error' ? 'outline-danger' : 'outline-primary'}
                      >
                        {
                      exportStatus === 'error'
                        ? 'Retry (Export All Settlements)'
                        : 'Export All Settlements'
                    }
                      </Button>
                    )
                  }
                {
                  exportStatus === 'success' && (
                  <div
                    className="fs-01 px-2 text-green"
                  >
                    Exported Successfully: You&apos;ll get a mail.
                  </div>
                  )
                }
              </Col>
              {
                razorpayxBalance >= 0 && (
                  <Col
                    xs="auto"
                    className="mx-2 fs-01 border border-primary py-1 px-2"
                  >
                    RazorpayX balance:&nbsp;
                    {razorpayxBalance}
                  </Col>
                )
              }
              {
                !!status && (
                  <Col
                    xs="auto"
                    className="mx-2 fs-01 border border-primary py-1 px-2"
                  >
                    Status:&nbsp;
                    <span className={`${status === 'ACTIVE' ? 'text-green' : 'text-danger'}`}>{status}</span>
                  </Col>
                )
              }
              {
                settlementMeta && (
                  <Col
                    xs="auto"
                    className="mx-2 fs-01 border border-primary py-1 px-2"
                  >
                    Total Settlement:&nbsp;
                    {settlementMeta.totalSettledAmount}
                  </Col>
                )
              }
            </Row>
            {selectSettlement && !!totalAmount && (
            <Row
              className="p-2 fs-0 align-items-center"
            >
              <Col
                xs="auto"
                className="pl-0"
              >
                Amount: &nbsp;
                <span
                  className="text-secondary"
                >
                  {totalAmount}
                </span>
              </Col>
              <Col
                xs="auto"
                className="pl-0"
              >
                Commission: &nbsp;
                <span
                  className="text-secondary"
                >
                  {totalCommission}
                </span>
              </Col>
              <Col
                xs="auto"
                className="pl-0"
              >
                Reconcile: &nbsp;
                <span
                  className="text-secondary"
                >
                  {totalReconcile}
                </span>
              </Col>
            </Row>
            )}
          </Col>
          <Col
            className="px-0 flex-grow-1 overflow-y"
          >
            <CustomTable
              headers={headers}
              content={settlements}
              keyField="reconciledId"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={settlementCount}
              hasPrev={hasPrevious}
              hasNext={hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

ReconciledSettlements.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDetailPage: PropTypes.bool,
};

ReconciledSettlements.defaultProps = {
  isDetailPage: false,
};

export default ReconciledSettlements;
