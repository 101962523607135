import React from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import {
  Button,
  Col, Container, Form, InputGroup, Row, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CustomTable, ErrorHandler, Svg } from '../../component/common';
import { ProcessingStatus } from '../../component/derived/table-list';
import { couponCampaigns, getUPIOptions } from '../../assets/api/axios';
import { dateString } from '../../utilities/Utils';
import CreateCampaign from './CreateCampaign';
import permission from '../../access&permissions/permission';
import { Constant } from '../../utilities';

function renderActiveStatus(data) {
  const currentDate = Date.now();
  const expiryDate = new Date(data.validTill).getTime();
  if (data.validTill) {
    if (expiryDate >= currentDate) {
      return <span className="text-success">&#10004;</span>;
    }
  }
  return <span className="text-danger">&#10006;</span>;
}

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    searchText = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 15;
  p = (p && p > 0) ? p : 1;
  return ({
    l,
    p,
    searchText,
  });
};

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canCreate = userPermission.includes(permission.COUPON_CREATION_WRITE);
    this.state = {
      loading: true,
      error: false,
      campaigns: null,
      rowsPerPage: param.l,
      page: param.p,
      param,
      createCampaignModal: false,
      selectCampaignId: '',
      editCampaignModal: false,
      searchText: param.searchText,
      upiOptions: [],
    };
  }

  componentDidMount = () => {
    this.handleLoad();
    this.loadUPIOptions();
  }

  loadUPIOptions = (retry = 3) => {
    getUPIOptions(
      'GET',
    ).then((res) => {
      this.setState({
        upiOptions: res.data.data,
      });
    }).catch(() => {
      if (retry) {
        this.loadUPIOptions(retry - 1);
      }
    });
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, searchText,
    } = newParam;
    const offset = (p - 1) * l;

    couponCampaigns(
      'GET',
      {
        offset: `${offset}`,
        limit: l,
        searchText,
      },
      {},
    ).then((res) => {
      this.setState({
        loading: false,
        campaigns: res.data,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        searchText: newParam.searchText,
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  onSubmitSearchText = () => {
    const { searchText, param } = this.state;
    if (searchText !== param.searchText) {
      this.handleRequestProcessing({ searchText, p: 1 });
    }
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onCancel = () => {
    const {
      loading, param,
    } = this.state;
    const {
      l, p,
    } = param;
    if (loading) {
      this.source.cancel();
    }
    this.setState({
      loading: false,
      error: false,
      rowsPerPage: l,
      page: p,
    });
  }

  render() {
    const {
      loading, error, campaigns, param, searchText,
      rowsPerPage, page, createCampaignModal,
      editCampaignModal, selectCampaignId, upiOptions,
    } = this.state;

    const headers = [
      {
        key: 'campaignId',
        displayText: 'Campaign Id',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                editCampaignModal: true,
                selectCampaignId: (data.campaignId).toString(),
              });
            }}
            className="fs-01 p-0"
          >
            {data.campaignId}
          </Button>
        ),
      },
      {
        key: 'campaignName',
        displayText: 'Campaign Name',
      },
      {
        key: 'couponPrefix',
        displayText: 'Coupon Prefix',
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => dateString(data.createdOn),
      },
      {
        key: 'validFrom',
        displayText: 'Valid From',
        renderer: (data) => dateString(data.validFrom),
      },
      {
        key: 'validTill',
        displayText: 'Valid Till',
        renderer: (data) => dateString(data.validTill),
      },
      {
        key: 'active',
        displayText: 'Active',
        renderer: (data) => renderActiveStatus(data),
      },
      {
        key: 'button',
        displayText: 'View',
        renderer: (data) => (
          <Link
            to={`/campaign/${data.campaignId}`}
            target="_blank"
          >
            <Svg
              svg="file"
              width="1.5rem"
              fill={Constant.Color.PRIMARY}
            />
          </Link>
        ),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white px-0"
      >
        {
          createCampaignModal && (
            <CreateCampaign
              show={createCampaignModal}
              onHide={() => { this.setState({ createCampaignModal: false }); }}
              handleRequestProcessing={this.handleRequestProcessing}
              upiOptions={upiOptions}
            />
          )
        }
        {
          editCampaignModal && (
            <CreateCampaign
              show={editCampaignModal}
              onHide={() => { this.setState({ editCampaignModal: false }); }}
              handleRequestProcessing={this.handleRequestProcessing}
              campaignId={selectCampaignId}
              upiOptions={upiOptions}
              editCampaign
            />
          )
        }
        {(() => {
          let showcase = null;
          if (!campaigns && loading) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <Spinner
                  animation="border"
                  variant="primary"
                />
              </div>
            );
          } else if (!campaigns && !loading && error) {
            showcase = (
              <div
                className="h-100 d-flex align-items-center
                justify-content-center"
              >
                <ErrorHandler
                  retryLogic={() => this.retry()}
                />
              </div>
            );
          } else if (campaigns) {
            showcase = (
              <>
                <ProcessingStatus
                  show={loading || error}
                  loading={loading}
                  error={error}
                  onRetry={this.retry}
                  onCancel={this.onCancel}
                />
                <Row
                  className="mx-0"
                >
                  <Col>
                    <Row>
                      <Col
                        className="p-3"
                        xs={6}
                      >
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              className="rounded-0"
                            >
                              <Svg
                                svg="search"
                                width="1rem"
                                fill={Constant.Color.DARK}
                              />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            key="searchText"
                            type="text"
                            placeholder="Search by Campaign Id / Name"
                            name="searchText"
                            className="fs-01 rounded-0"
                            value={searchText}
                            onChange={(e) => {
                              this.setState({
                                searchText: e.target.value,
                              });
                            }}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                this.onSubmitSearchText();
                              }
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        className="d-flex justify-content-end p-3"
                      >
                        <Button
                          variant="primary"
                          className="fs-01 font-weight-bold"
                          onClick={() => {
                            this.setState({
                              createCampaignModal: true,
                            });
                          }}
                          disabled={!this.canCreate}
                        >
                          <Svg
                            svg="add"
                            width="20"
                            height="20"
                          />
                          &nbsp;
                          CREATE CAMPAIGN
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    className="px-0 pt-2 table-section"
                  >
                    <CustomTable
                      headers={headers}
                      content={campaigns.results}
                      keyField="campaignId"
                      l={param.l}
                      p={param.p}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      totalItems={campaigns.count}
                      hasPrev={campaigns.hasPrev}
                      hasNext={campaigns.hasNext}
                      onNext={this.onNext}
                      onPrev={this.onPrev}
                      onSubmitPage={this.onSubmitPage}
                      onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                      updateRowsPageInput={this.handleRowsPageInput}

                    />
                  </Col>
                </Row>
              </>
            );
          }
          return showcase;
        })()}
      </Container>
    );
  }
}

Campaign.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default Campaign;
