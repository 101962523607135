/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Spinner, Button, Form,
} from 'react-bootstrap';
import {
  generateFundAccount, panCardDetails, retailerProfile, storeProfile, testOrder,
  exotelCallDetails, verificationCall, updateVpaStatus, switchSettlementMode,
} from '../../assets/api/axios';
import OnboardingDetails from '../../component/derived/onboarding-state/OnboardingDetails';
import { CustomModal } from '../../component/common';
import RetailerStoryForm from '../../layouts/review-panal/RetailerStoryForm';
import UploadKYC from '../../layouts/onboarding-state/UploadKYC';
import UploadBankDetails from '../../layouts/onboarding-state/UploadBankDetails';
import Permission from '../../access&permissions/permission';
import VerifyOnboardingDetails from '../../layouts/onboarding-state/VerifyOnboardingDetails';
import BankDetailsVerificationCall from '../../layouts/onboarding-state/BankDetailsVerificationCall';
import { dateString } from '../../utilities/Utils';
import KYCVerification from '../../component/derived/onboarding/KYCVerification';
import ViewCommentsHistory from '../../layouts/onboarding-state/ViewCommentsHistory';

const reasons = [
  {
    displayName: 'Incorrect ID Proof',
    id: 'Incorrect_ID_Proof',
  },
  {
    displayName: 'Expired KYC Documents',
    id: 'Expired_KYC_Documents',
  },
  {
    displayName: 'No Shop Board',
    id: 'No_Shop_Board',
  },
  {
    displayName: 'Poor Image Quality',
    id: 'Poor_Image_Quality',
  },
  {
    displayName: 'KYC Mismatch',
    id: 'KYC_Mismatch',
  },
  {
    displayName: 'Owner Missing In Shop Photo',
    id: 'Owner_Missing_In_Shop_Photo',
  },
  {
    displayName: 'Others',
    id: 'Others',
  },
];

const bankReasons = [
  {
    displayName: 'Account Holder Name and KYC Mismatch',
    id: 'Account_Holder_Name_and_KYC_Mismatch',
  },
  {
    displayName: 'Incorrect IFSC Code',
    id: 'Incorrect_IFSC_Code',
  },
  {
    displayName: 'Duplicate Bank Account Details',
    id: 'Duplicate_Bank_Account_Details',
  },
  {
    displayName: 'Bank Account Does Not Exist',
    id: 'Bank_Account_Does_Not_Exist',
  },
  {
    displayName: 'Fake/Edited PAN Card Details',
    id: 'Fake_Edited_PAN_Card_Details',
  },
  {
    displayName: 'Aadhar and The Legal Retailer Name Is Not Matching',
    id: 'Aadhar_and_The_Legal_Retailer_Name_Is_Not_Matching',
  },
  {
    displayName: 'DOB Details Mismatch',
    id: 'DOB_Details_Mismatch',
  },
  {
    displayName: 'Others',
    id: 'Others',
  },
];

class OnboardingStates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeCode: props.match.params.code,
      profile: null,
      loading: true,
      error: false,
      selectedState: 'KYC',
      testOrderInfo: '',
      retailerOwnStory: null,
      onboarding: false,
      personalKycType: false,
      businessKycType: false,
      shopImageInside: false,
      shopImageOutside: false,
      personalKycImage: false,
      ownerImage: false,
      name: false,
      ownerName: false,
      businessKycId: false,
      personalKycId: false,
      pinCode: false,
      businessKycStatus: '',
      personalKycStatus: '',
      resetList: [],
      submitLoading: false,
      submitMessage: false,
      onboardingStatus: '',
      modifyStatus: '',
      accountName: false,
      accountNumber: false,
      ifscCode: false,
      gstNumber: false,
      panHolderName: false,
      cancelledChequeUrl: false,
      panImage: false,
      bankAccountStatus: '',
      reason: '',
      comment: '',
      bankComment: '',
      bankReason: '',
      submitError: '',
      uploadDetails: '',
      serverMessage: '',
      firstOrderStatus: '',
      firstOrderComment: '',
      showDetails: false,
      getDetailsLoading: false,
      getDetailsError: '',
      signzyBankDetails: null,
      showCallModal: false,
      lastCallDate: '',
      lastCallComment: '',
      showCommentHistory: false,
      verificationCallPending: false,
      updatedVpaStatus: '',
      changingSettlementMode: false,
      activeTab: '',
    };
  }

  componentDidMount = () => {
    const { storeCode } = this.state;
    this.getStoreProfile();
    this.bankDetailsVerificationCall();
    this.verificationCallDetails();
    retailerProfile(
      'GET',
      storeCode,
    ).then((res) => {
      this.setState({
        retailerOwnStory: res.data,
      });
    }).catch(() => { });
  }

  getStoreProfile = () => {
    const { storeCode } = this.state;
    storeProfile(
      'GET',
      storeCode,
      null,
      {
        includeFundDetail: true,
      },
    ).then((res) => {
      this.setProfileDetails(res.data);
      this.setState({
        loading: false,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
  }

  setProfileDetails = (profile = {}) => {
    this.setState({
      profile,
      onboardingStatus: profile.kyc.status,
      businessKycStatus: profile.kyc.businessKycStatus,
      personalKycStatus: profile.kyc.personalKycStatus,
      bankAccountStatus: profile.account.bankAccountStatus,
      firstOrderStatus: profile.account.firstOrderVerificationStatus,
      updatedVpaStatus: profile.account?.vpaDetails?.crmStatus,
      upiComment: profile.account?.vpaDetails?.comment,
    });
  }

  bankDetailsVerificationCall = () => {
    const { storeCode } = this.state;
    exotelCallDetails(
      'GET',
      null,
      '',
      {
        storeCode,
        callDescription: 'Bank_Details_Verification',
      },
    ).then((res) => {
      this.setState({
        lastCallDate: res.data.lastCallDate,
        lastCallComment: res.data.callComment,
      });
    }).catch(() => {});
  }

  createTestOrder = (retryCount = 3) => {
    const { storeCode } = this.state;
    this.setState({
      testOrderInfo: 'generating',
    });
    testOrder(
      'POST',
      storeCode,
    ).then(() => {
      this.setState({
        onboarding: false,
        testOrderInfo: 'generated',
      });
    }).catch(() => {
      if (retryCount) {
        this.createTestOrder(retryCount - 1);
        return;
      }
      this.setState({
        onboarding: false,
        testOrderInfo: 'error',
      });
    });
  }

  verificationCallDetails = () => {
    const { storeCode } = this.state;
    verificationCall(
      'GET',
      {
        code: storeCode,
      },
      storeCode,
    ).then((res) => {
      if (res && res.data && res.data.status && res.data.status === 'VerificationPending') {
        this.setState({
          verificationCallPending: true,
        });
      }
    }).catch(() => {});
  }

  // IVR Verification call to retailer
  getVerificationCall = () => {
    const { storeCode } = this.state;
    verificationCall(
      'POST',
      {
        source: 'CRM',
        code: storeCode,
      },
    ).then(() => {

    }).catch(() => {

    });
  }

  launchShopOnline = (retryCount = 3) => {
    const { storeCode, profile, verificationCallPending } = this.state;
    this.setState({
      onboarding: true,
    });
    storeProfile(
      'PATCH',
      storeCode,
      {
        setupCompleted: true,
        onHomeDelivery: true,
        isDelivering: true,
      },
    ).then((res) => {
      if (verificationCallPending) {
        this.getVerificationCall();
      }
      this.setState({
        profile: { ...profile, ...res.data },
      });
    }).catch((e) => {
      if (retryCount) {
        this.launchShopOnline(retryCount - 1);
        return;
      }
      this.setState({
        onboarding: false,
      });
      if (
        e
        && e.response
        && e.response.data
        && e.response.data.server_message
      ) {
        this.setState({
          serverMessage: e.response.data.server_message,
        });
      }
    });
  }

  kycStatus = (status) => {
    const { userPermission } = this.props;
    if (!status) {
      return <>{status || 'NA'}</>;
    }
    return (
      <select
        className={`form-control font-weight-bold ${
          // eslint-disable-next-line no-nested-ternary
          (status === 'APPROVED' || status === 'COMPLETED')
            ? 'text-green'
            : status === 'DENIED'
              ? 'text-danger'
              : ''
        }`}
        value={status}
        onChange={(event) => {
          this.setState({
            onboardingStatus: event.target.value,
            modifyStatus: event.target.value,
            submitMessage: false,
            submitError: '',
          });
        }}
        disabled={!userPermission.includes(Permission.MODIFY_KYC_STATUS)}
      >
        <option value="PENDING">PENDING</option>
        <option value="WAITLIST">WAITLIST</option>
        <option value="APPROVED">APPROVED</option>
        <option value="DENIED">DENIED</option>
        <option value="COMPLETED">COMPLETED</option>
        <option value="RESET">RESET</option>
      </select>
    );
  }

  kycRejectionReason = () => {
    const { reason } = this.state;
    return (
      <select
        className="form-control"
        value={reason}
        onChange={(event) => {
          if (event.target.value !== '') {
            this.setState({
              reason: event.target.value,
              submitMessage: false,
            });
          }
        }}
      >
        <option value="">Select Reason</option>
        {
          reasons.map((item) => (
            <option value={item.id}>{item.displayName}</option>
          ))
        }
      </select>
    );
  }

  toggleUploadDetails = (selectedState) => {
    this.setState({
      uploadDetails: selectedState,
    });
  }

  handleUploadDetails = (profile) => {
    this.setState({
      profile,
      uploadDetails: '',
      onboardingStatus: profile.kyc.status,
      bankAccountStatus: profile.account.bankAccountStatus,
      firstOrderStatus: profile.account.firstOrderVerificationStatus,
    });
  }

  bankStatus = (status) => {
    if (!status) {
      return <>{status || 'NA'}</>;
    }
    return (
      <select
        className="form-control"
        value={status}
        onChange={(event) => {
          this.setState({
            bankAccountStatus: event.target.value,
            modifyStatus: event.target.value,
            submitMessage: false,
            submitError: '',
          });
        }}
        disabled={status === 'AUTO_VERIFICATION'}
      >
        <option value="PENDING">PENDING</option>
        <option
          value="AUTO_VERIFICATION"
          disabled
          hidden={status !== 'AUTO_VERIFICATION'}
        >
          AUTO VERIFYING
        </option>
        <option value="WAITLIST">WAITLIST</option>
        <option value="APPROVED">APPROVED</option>
        <option value="DENIED">DENIED</option>
        <option value="RESET">RESET</option>
      </select>
    );
  }

  bankRejectionReason = () => {
    const { bankReason } = this.state;
    return (
      <select
        className="form-control"
        value={bankReason}
        onChange={(event) => {
          if (event.target.value !== '') {
            this.setState({
              bankReason: event.target.value,
              submitMessage: false,
            });
          }
        }}
      >
        <option value="">Select Reason</option>
        {
          bankReasons.map((item) => (
            <option value={item.id} key={item.id}>{item.displayName}</option>
          ))
        }
      </select>
    );
  }

  firstOrderVerification = (status) => {
    const { profile } = this.state;
    if (!status) {
      return <>{status || 'NA'}</>;
    }
    return (
      <select
        className="form-control"
        value={status}
        onChange={(event) => {
          this.setState({
            firstOrderStatus: event.target.value,
            modifyStatus: event.target.value,
            submitMessage: false,
            submitError: '',
          });
        }}
        disabled={profile.kyc.status !== 'COMPLETED' && profile.kyc.status !== 'APPROVED'}
      >
        <option value="PENDING">PENDING</option>
        <option value="APPROVED">APPROVED</option>
        <option value="REJECTED">REJECTED</option>
      </select>
    );
  }

  deliveryRadii = (radii) => {
    if (radii.length === 0) {
      return (<>NA</>);
    }
    return radii.map((item) => (
      <div
        className="d-flex"
      >
        <span>
          {item.radius / 1000}
          &nbsp;KM
        </span>
        <span>&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;</span>
        <span>
          &#8377;
          {item.amount}
        </span>
      </div>
    ));
  }

  handleCheckedItem = (property, name1, name2 = '') => {
    const { resetList } = this.state;
    this.setState(() => {
      if (name2 !== '') {
        return {
          [name1]: !property,
          resetList: [
            ...resetList,
            name1,
            name2,
          ],
        };
      }
      return {
        [name1]: !property,
        resetList: [
          ...resetList,
          name1,
        ],
      };
    });
  }

  handleUncheckedItem = (property, name1, name2 = '') => {
    const { resetList } = this.state;
    this.setState({
      [name1]: !property,
      resetList: resetList.filter((item) => (item !== name1 && item !== name2)),
    });
  }

  handleKycStatus = () => {
    const {
      storeCode, resetList, onboardingStatus,
      reason, comment,
    } = this.state;
    this.setState({
      submitLoading: true,
    });
    let data = null;
    if (onboardingStatus === 'RESET') {
      data = {
        kyc: {
          status: onboardingStatus,
          keys: resetList,
          kycRejectionReason: reason,
          kycRejectionComment: comment,
        },
      };
    } else {
      data = {
        kyc: {
          status: onboardingStatus,
          kycRejectionReason: reason,
          kycRejectionComment: comment,
          addedFrom: 'CRM Onboarding Status KYC Details',
          comment: `${reason ? `${reason} - ` : ''}${comment ? `${comment} - ` : ''}${onboardingStatus}`,
          storeCode,
        },
      };
    }

    storeProfile(
      'PATCH',
      storeCode,
      data,
    ).then((res) => {
      this.setState({
        profile: res.data,
        onboardingStatus: res.data.kyc.status,
        modifyStatus: '',
        submitLoading: false,
        submitMessage: true,
        personalKycType: false,
        businessKycType: false,
        shopImageInside: false,
        shopImageOutside: false,
        personalKycImage: false,
        ownerImage: false,
        resetList: [],
        comment: '',
        reason: '',
        submitError: '',
      });
    }).catch(() => {
      this.setState({
        submitLoading: false,
        submitError: 'Oops Something went wrong, Please try again!!',
      });
    });
  }

  handleBankStatus = () => {
    const {
      storeCode, resetList, bankAccountStatus,
      bankReason, bankComment,
    } = this.state;
    this.setState({
      submitLoading: true,
      activeTab: 'BANK',
    });
    let data = null;
    if (bankAccountStatus === 'RESET') {
      data = {
        bank: {
          status: bankAccountStatus,
          keys: resetList,
          bankRejectionReason: bankReason,
          bankRejectionComment: bankComment,
        },
      };
    } else {
      data = {
        bank: {
          status: bankAccountStatus,
          bankRejectionReason: bankReason,
          bankRejectionComment: bankComment,
        },
      };
    }

    storeProfile(
      'PATCH',
      storeCode,
      data,
    ).then((res) => {
      this.setState({
        profile: res.data,
        bankAccountStatus: res.data.account.bankAccountStatus,
        modifyStatus: '',
        submitLoading: false,
        submitMessage: true,
        accountName: false,
        accountNumber: false,
        ifscCode: false,
        gstNumber: false,
        panHolderName: false,
        cancelledChequeUrl: false,
        panImage: false,
        bankReason: '',
        bankComment: '',
        activeTab: '',
      });
    }).catch(() => {
      this.setState({
        submitLoading: false,
        activeTab: '',
      });
    });
  }

  saveBusinessAndPersonalKycStatus = (
    name, value,
  ) => {
    const {
      storeCode,
    } = this.state;
    storeProfile(
      'PATCH',
      storeCode,
      {
        kyc: {
          [name]: value,
        },
      },
    ).then((res) => {
      this.setProfileDetails(res.data);
    })
      .catch(() => {});
  }

  clearSubmitMeta = () => {
    setTimeout(() => {
      this.setState({
        submitError: '',
        submitMessage: '',
        // activeUpiId: '',
      });
    }, 5000);
  };

  updateStoreData = () => {
    const { storeCode } = this.state;
    storeProfile(
      'GET',
      storeCode,
      null,
      {
        includeFundDetail: true,
      },
    ).then((response) => {
      this.setState({
        profile: response.data,
        submitLoading: false,
        submitMessage: true,
        upiComment: response.data.account?.vpaDetails?.comment,
      });
    }).catch(() => {
      this.setState({
        submitError: 'Oops Something went wrong, Please try again!!',
        submitLoading: false,
      });
    }).finally(() => {
      this.clearSubmitMeta();
    });
  }

  handleFundAccount = () => {
    const { storeCode } = this.state;
    this.setState({
      submitLoading: true,
    });
    generateFundAccount(
      'POST',
      {
        storeId: storeCode,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.updateStoreData();
      } else {
        throw new Error();
      }
    }).catch((error) => {
      this.setState(() => {
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data[0]
        ) {
          return {
            submitError: error.response.data[0].client_message,
            submitLoading: false,
          };
        }
        return {
          submitError: 'Oops Something went wrong, Please try again!!',
          submitLoading: false,
          // activeUpiId: '',
        };
      });
      this.clearSubmitMeta();
    });
  }

  handleFirstOrder = () => {
    const { storeCode, firstOrderStatus, firstOrderComment } = this.state;
    this.setState({
      submitLoading: true,
    });
    storeProfile(
      'PATCH',
      storeCode,
      {
        kyc: {
          firstOrderVerificationStatus: firstOrderStatus,
          firstOrderVerificationComment: firstOrderComment,
        },
      },
    ).then((res) => {
      this.setState({
        profile: res.data,
        firstOrderStatus: res.data.account.firstOrderVerificationStatus,
        firstOrderComment: '',
        modifyStatus: '',
        submitLoading: false,
        submitMessage: true,
        submitError: '',
      });
    }).catch(() => {
      this.setState({
        submitLoading: false,
        submitError: 'Oops Something went wrong, Please try again!!',
      });
    });
  }

  handleGetBankDetails = () => {
    const { storeCode } = this.state;
    this.setState({
      getDetailsLoading: true,
      getDetailsError: '',
    });
    panCardDetails(
      'GET',
      {
        action: 'FETCHPANDETAILS',
        storeCode,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          signzyBankDetails: res.data,
          showDetails: true,
          getDetailsLoading: false,
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      this.setState(() => {
        if (
          err
          && err.response
          && err.response.status === 400
          && err.response.data
          && err.response.data[0]
        ) {
          return {
            getDetailsLoading: false,
            getDetailsError: err.response.data[0].client_message,
          };
        }
        return {
          getDetailsLoading: false,
          getDetailsError: 'Oops Something went wrong. Please try again!!',
        };
      });
    });
  }

  verifyDetails = (state) => {
    const {
      profile, showDetails, getDetailsLoading, getDetailsError,
      signzyBankDetails,
    } = this.state;
    const bankDetails = [
      {
        id: 1,
        label: 'Pan Number',
        value: (signzyBankDetails && signzyBankDetails.number) || '--',
      },
      {
        id: 2,
        label: 'Pan Holder Name',
        value: (signzyBankDetails && signzyBankDetails.name) || '--',
      },
      {
        id: 3,
        label: 'Pan Status',
        value: (signzyBankDetails && signzyBankDetails.panStatus) || '--',
      },
      {
        id: 4,
        label: 'Aadhaar Submit Status',
        value: (signzyBankDetails && signzyBankDetails.aadhaarSeedingStatus) || '--',
      },
    ];

    switch (state) {
      case 'Bank':
        return (
          <VerifyOnboardingDetails
            id="BANK_DETAILS"
            buttonTitle="Get PAN Details"
            details={bankDetails}
            isDisabled={!profile.kyc.panNumber || showDetails}
            showDetails={showDetails}
            loading={getDetailsLoading}
            error={getDetailsError}
            handleGetDetails={this.handleGetBankDetails}
          />
        );
      default:
        return (<></>);
    }
  }

  setSubmitError = (err) => {
    this.setState(() => {
      if (
        err
        && err.response
        && err.response.status === 400
        && err.response.data
        && err.response.data[0]
      ) {
        return {
          submitError: err.response.data[0].client_message,
        };
      }
      return {
        submitError: 'Oops Something went wrong. Please try again!!',
      };
    });
  }

  updateUpiStatus = () => {
    const { profile, updatedVpaStatus, upiComment } = this.state;
    this.setState({ submitLoading: true, activeTab: 'UPI' });
    updateVpaStatus(
      profile.code,
      {
        status: updatedVpaStatus,
        vpa: profile?.account?.vpaDetails?.vpa,
        comment: updatedVpaStatus !== 'DENIED' ? '' : upiComment,
      },
    ).then(() => {
      this.setState({ upiComment: '' });
      this.updateStoreData();
    }).catch((err) => {
      this.setSubmitError(err);
    }).finally(() => {
      this.setState({ submitLoading: false, activeTab: '' });
      this.clearSubmitMeta();
    });
  }

  switchMode = (mode = '') => {
    const { profile } = this.state;
    this.setState({
      changingSettlementMode: true,
      activeTab: mode === 'VPA' ? 'UPI' : 'BANK',
    });
    switchSettlementMode(
      profile.code,
      {
        accountType: mode,
      },
    ).then(() => {
      this.updateStoreData();
    }).catch((err) => {
      this.setSubmitError(err);
    }).finally(() => {
      this.setState({ changingSettlementMode: false, activeTab: '' });
      this.clearSubmitMeta();
    });
  };

  getUpiDetails = (state) => {
    const {
      profile, updatedVpaStatus, submitError,
      submitMessage, changingSettlementMode, upiComment,
      submitLoading, activeTab,
    } = this.state;
    const upiDetails = (details) => [
      {
        id: 1,
        label: 'UPI ID',
        value: details.vpa,
      },
      {
        id: 2,
        label: 'Beneficiary name',
        value: details.vpaHolderName,
      },
      {
        id: 3,
        label: 'Shop Name',
        value: details.shopName,
      },
      {
        id: 4,
        label: 'Shop Name matching percentage',
        value: (
          <span
            className={`${details.shopNameMatchingPercent >= 80 ? 'text-green' : 'text-danger'}`}
          >
            {details.shopNameMatchingPercent || '-- --'}
          </span>
        ),
      },
      {
        id: 5,
        label: 'Owner Name',
        value: details.ownerName,
      },
      {
        id: 6,
        label: 'Owner Name matching percentage',
        value: (
          <span
            className={`${details.ownerNameMatchingPercent >= 80 ? 'text-green' : 'text-danger'}`}
          >
            {details.ownerNameMatchingPercent || '-- --'}
          </span>
        ),
      },
      {
        id: 7,
        label: 'UPI status',
        value: (
          <select
            className="form-control"
            value={updatedVpaStatus}
            onChange={(event) => {
              this.setState({
                updatedVpaStatus: event.target.value,
              });
            }}
            disabled={
              submitLoading
            }
          >
            <option value="APPROVED">APPROVED</option>
            <option value="DENIED">DENIED</option>
            <option value="RESET">RESET</option>
          </select>
        ),
      },
      {
        id: 8,
        label: 'Modified by',
        value: details.modifiedBy,
      },
      {
        id: 9,
        label: 'Comment',
        value: (
          (updatedVpaStatus === 'DENIED') ? (
            <input
              className="form-control"
              type="text"
              name="upiComment"
              placeholder="*Required if status is Denied"
              value={upiComment}
              onChange={(event) => {
                this.setState({
                  upiComment: event.target.value,
                });
              }}
              maxLength="250"
            />
          ) : (
            <>--</>
          )
        ),
      },
    ];

    switch (state) {
      case 'Bank':
        return (profile?.account?.vpaDetails && (
          <Col xs={24} className="px-0">
            <OnboardingDetails
              {...this.props}
              title="UPI Details"
              id="UPI Details"
              tab="UPI_DETAILS"
              details={upiDetails(profile?.account?.vpaDetails)}
              showButton
              accountDetails={profile?.account}
              upiComment={upiComment}
              handleSubmit={this.updateUpiStatus}
              switchSettlementMode={() => this.switchMode('VPA')}
              updatedVpaStatus={updatedVpaStatus}
              submitLoading={activeTab === 'UPI' && (changingSettlementMode || submitLoading)}
              submitError={activeTab === 'UPI' ? submitError : ''}
              submitMessage={activeTab === 'UPI' ? submitMessage : ''}
            />
          </Col>
        ));
      default:
        return null;
    }
  }

  getDetails = (state) => {
    const {
      profile, personalKycType,
      businessKycType, shopImageInside, shopImageOutside, ownerImage, personalKycImage,
      resetList, submitMessage, submitLoading, onboardingStatus, modifyStatus,
      accountName, accountNumber, ifscCode, gstNumber, cancelledChequeUrl, panHolderName,
      panImage, bankAccountStatus, reason, comment, bankComment, bankReason, submitError,
      lastCallDate, lastCallComment, name, ownerName, personalKycId, businessKycId,
      pinCode, personalKycStatus, businessKycStatus,
      firstOrderStatus, firstOrderComment, changingSettlementMode,
      activeTab, upiComment,
    } = this.state;
    const { userPermission } = this.props;
    const cash = profile.paymentMode.find((item) => item.code === 'CASH');
    const digital = profile.paymentMode.find((item) => item.code === 'DIGITAL');
    const KYCDetail = [
      {
        id: 1,
        label: 'Shop Name',
        value: (
          <Row
            className="align-items-center"
          >
            {
              onboardingStatus === 'RESET' && (
                <Col
                  xs={2}
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    checked={name}
                    onChange={() => {
                      if (name) {
                        this.handleUncheckedItem(name, 'name');
                      } else {
                        this.handleCheckedItem(name, 'name');
                      }
                    }}
                  />
                </Col>
              )
            }
            <Col>
              {profile.displayName}
            </Col>
          </Row>
        ),
      },
      {
        id: 2,
        label: 'Shop Name as per Business KYC Document',
        value: (
          <span
            className="text-green"
          >
            {profile.kyc.kycShopName || '-- --'}
          </span>
        ),
      },
      {
        id: 3,
        label: 'Shop Name Match percentage',
        value: profile.kyc.shopNameMatchPercentage,
      },
      {
        id: 4,
        label: 'Shop Pincode',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={pinCode}
                  onChange={() => {
                    if (pinCode) {
                      this.handleUncheckedItem(pinCode, 'pinCode', 'pinCode');
                    } else {
                      this.handleCheckedItem(pinCode, 'pinCode', 'pinCode');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.pinCode}
            </Col>
          </Row>
        ),
      },
      {
        id: 5,
        label: 'Business KYC Document Pincodes',
        value: (
          <span
            className="text-green"
          >
            {profile.kyc.kycPinCodes || '-- --'}
          </span>
        ),
      },
      {
        id: 6,
        label: 'Business KYC Document Certificate Active',
        value: (profile.kyc.isBusinessKycCertificateActive ? (
          <span
            className="text-green"
          >
            YES
          </span>
        ) : (
          <span
            className="text-danger"
          >
            NO
          </span>
        )),
      },
      {
        id: 7,
        label: 'Shop Inside Image',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={shopImageInside}
                  onChange={() => {
                    if (shopImageInside) {
                      this.handleUncheckedItem(shopImageInside, 'shopImageInside');
                    } else {
                      this.handleCheckedItem(shopImageInside, 'shopImageInside');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {
                profile.kyc.shopImages.inside ? (
                  <a href={profile.kyc.shopImages.inside} target="_blank" rel="noopener noreferrer">
                    <img src={profile.kyc.shopImages.inside} alt="" width="100px" height="100px" />
                  </a>
                ) : '-- No Image --'
              }
            </Col>
          </Row>
        ),
      },
      {
        id: 8,
        label: 'Shop Outside Image',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={shopImageOutside}
                  onChange={() => {
                    if (shopImageOutside) {
                      this.handleUncheckedItem(shopImageOutside, 'shopImageOutside');
                    } else {
                      this.handleCheckedItem(shopImageOutside, 'shopImageOutside');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {
                profile.kyc.shopImages.outside ? (
                  <a href={profile.kyc.shopImages.outside} target="_blank" rel="noopener noreferrer">
                    <img src={profile.kyc.shopImages.outside} alt="" width="100px" height="100px" />
                  </a>
                ) : '-- No Image --'
              }
            </Col>
          </Row>
        ),
      },
      {
        id: 9,
        label: 'Business Kyc Type',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={businessKycType}
                  onChange={() => {
                    if (businessKycType) {
                      this.handleUncheckedItem(businessKycType, 'businessKycType');
                    } else {
                      this.handleCheckedItem(businessKycType, 'businessKycType');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.kyc.businessKycType}
            </Col>
          </Row>
        ),
      },
      {
        id: 10,
        label: 'Business KYC Id',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={businessKycId}
                  onChange={() => {
                    if (businessKycId) {
                      this.handleUncheckedItem(businessKycId, 'businessKycId');
                    } else {
                      this.handleCheckedItem(businessKycId, 'businessKycId');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.kyc.businessKycId}
            </Col>
          </Row>
        ),
      },
      {
        id: 11,
        label: 'Business KYC Status',
        value: (
          <Row
            className="align-items-center"
          >
            <Col
              className=""
            >
              <select
                name="businessKycStatus"
                id=""
                value={businessKycStatus}
                className={`form-control font-weight-bold ${
                  // eslint-disable-next-line no-nested-ternary
                  businessKycStatus === 'APPROVED'
                    ? 'text-green'
                    : businessKycStatus === 'DENIED'
                      ? 'text-danger'
                      : ''
                }`}
                onChange={(event) => {
                  this.setState({
                    businessKycStatus: event.target.value,
                  });
                }}
              >
                <option
                  value=""
                  className="text-green"
                >
                  -- -- --
                </option>
                <option
                  value="PENDING"
                  className="text-gray"
                >
                  PENDING
                </option>
                <option
                  value="DENIED"
                  className="text-danger"
                >
                  DENIED
                </option>
                <option
                  value="APPROVED"
                  className="text-green"
                >
                  APPROVED
                </option>
              </select>
            </Col>
            <Col
              xs="auto"
            >
              <Button
                variant="primary"
                className="fs-1 font-weight-bold py-1"
                disabled={!businessKycStatus}
                onClick={() => {
                  this.saveBusinessAndPersonalKycStatus('businessKycStatus', businessKycStatus);
                }}
              >
                SAVE
              </Button>
            </Col>
          </Row>
        ),
      },
      {
        id: 12,
        label: 'Business KYC Failed Reasons',
        value: (
          <Row>
            {
              (profile.kyc.businessKycFailedReasons || []).map((item) => (
                <Col
                  xs={24}
                  className="pb-1 text-danger"
                  key={item}
                >
                  {item}
                </Col>
              ))
            }
          </Row>
        ),
      },
      {
        id: 13,
        label: 'Business KYC Updated By',
        value: profile.kyc.businessKycStatusUpdatedBy || '-- --',
      },
      {
        id: 14,
        label: 'Owner Name',
        value: (
          <Row
            className="align-items-center"
          >
            {
              onboardingStatus === 'RESET' && (
                <Col
                  xs={2}
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    checked={ownerName}
                    onChange={() => {
                      if (ownerName) {
                        this.handleUncheckedItem(ownerName, 'ownerName');
                      } else {
                        this.handleCheckedItem(ownerName, 'ownerName');
                      }
                    }}
                  />
                </Col>
              )
            }
            <Col>
              {profile.ownerName}
            </Col>
          </Row>
        ),
      },
      {
        id: 15,
        label: 'Owner name as per Personal KYC Document',
        value: (
          <span
            className="text-green"
          >
            {profile.kyc.kycOwnerName || '-- --'}
          </span>
        ),
      },
      {
        id: 16,
        label: 'Owner name match percentage',
        value: profile.kyc.ownerNameMatchPercentage,
      },
      {
        id: 17,
        label: 'Owner Image',
        value: (
          <Row
            className="align-items-center"
          >
            {
              onboardingStatus === 'RESET' && (
                <Col
                  xs={2}
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    checked={ownerImage}
                    onChange={() => {
                      if (ownerImage) {
                        this.handleUncheckedItem(ownerImage, 'ownerImage');
                      } else {
                        this.handleCheckedItem(ownerImage, 'ownerImage');
                      }
                    }}
                  />
                </Col>
              )
            }
            <Col>
              {
                profile.kyc.ownerImage ? (
                  <a href={profile.kyc.ownerImage} target="_blank" rel="noopener noreferrer">
                    <img src={profile.kyc.ownerImage} alt="" width="100px" height="100px" />
                  </a>
                ) : '-- No Image --'
              }
            </Col>
          </Row>
        ),
      },
      {
        id: 18,
        label: 'Personal KYC Type',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={personalKycType}
                  onChange={() => {
                    if (personalKycType) {
                      this.handleUncheckedItem(personalKycType, 'personalKycType', 'personalKycType');
                    } else {
                      this.handleCheckedItem(personalKycType, 'personalKycType', 'personalKycType');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.kyc.personalKycType}
            </Col>
          </Row>
        ),
      },
      {
        id: 19,
        label: 'Personal KYC Id',
        value: (
          <Row
            className="align-items-center"
          >
            {onboardingStatus === 'RESET' && (
              <Col
                xs={2}
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={personalKycId}
                  onChange={() => {
                    if (personalKycId) {
                      this.handleUncheckedItem(personalKycId, 'personalKycId');
                    } else {
                      this.handleCheckedItem(personalKycId, 'personalKycId');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.kyc.personalKycId}
            </Col>
          </Row>
        ),
      },
      {
        id: 20,
        label: 'Personal Kyc Image',
        value: (
          <Row
            className="align-items-center"
          >
            {
              onboardingStatus === 'RESET' && (
                <Col
                  xs={2}
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    checked={personalKycImage}
                    onChange={() => {
                      if (personalKycImage) {
                        this.handleUncheckedItem(personalKycImage, 'personalKycImage');
                      } else {
                        this.handleCheckedItem(personalKycImage, 'personalKycImage');
                      }
                    }}
                  />
                </Col>
              )
            }
            <Col>
              {
                 profile.kyc.personalKycImage ? (
                   <a href={profile.kyc.personalKycImage} target="_blank" rel="noopener noreferrer">
                     <img src={profile.kyc.personalKycImage} alt="" width="100px" height="100px" />
                   </a>
                 ) : '-- No Image --'
               }
            </Col>
          </Row>
        ),
      },
      {
        id: 21,
        label: 'Personal KYC Status',
        value: (
          <Row
            className="align-items-center"
          >
            <Col
              className=""
            >
              <select
                name="personalKycStatus"
                id=""
                value={personalKycStatus}
                className={`form-control font-weight-bold ${
                  // eslint-disable-next-line no-nested-ternary
                  personalKycStatus === 'APPROVED'
                    ? 'text-green'
                    : personalKycStatus === 'DENIED'
                      ? 'text-danger'
                      : ''
                }`}
                onChange={(event) => {
                  this.setState({
                    personalKycStatus: event.target.value,
                  });
                }}
              >
                <option
                  value=""
                  className="text-green"
                >
                  -- -- --
                </option>
                <option
                  value="PENDING"
                  className="text-gray"
                >
                  PENDING
                </option>
                <option
                  value="DENIED"
                  className="text-danger"
                >
                  DENIED
                </option>
                <option
                  value="APPROVED"
                  className="text-green"
                >
                  APPROVED
                </option>
              </select>
            </Col>
            <Col
              xs="auto"
            >
              <Button
                variant="primary"
                className="fs-1 font-weight-bold py-1"
                disabled={!personalKycStatus}
                onClick={() => {
                  this.saveBusinessAndPersonalKycStatus('personalKycStatus', personalKycStatus);
                }}
              >
                SAVE
              </Button>
            </Col>
          </Row>
        ),
      },
      {
        id: 22,
        label: 'Personal KYC Failed Reasons',
        value: (
          <Row>
            {
              (profile.kyc.personalKycFailedReasons || []).map((item) => (
                <Col
                  xs={24}
                  className="pb-1 text-danger"
                  key={item}
                >
                  {item}
                </Col>
              ))
            }
          </Row>
        ),
      },
      {
        id: 23,
        label: 'Personal KYC Updated By',
        value: profile.kyc.personalKycStatusUpdatedBy || '-- --',
      },
      {
        id: 24,
        label: 'Kyc Status Modify Reason',
        value:
          (
            <>
              {(() => {
                let showcase = null;
                if (
                  (profile.kyc.status === 'DENIED' || profile.kyc.status === 'WAITLIST')
                  && (profile.kyc.kycRejectionReason || profile.kyc.kycRejectionComment)
                ) {
                  showcase = (
                    `${profile.kyc.kycRejectionReason}
                    ${profile.kyc.kycRejectionComment ? `- ${profile.kyc.kycRejectionComment}` : ''}`
                  );
                } else if (profile.kyc.status === 'APPROVED' && profile.kyc.kycRejectionComment) {
                  showcase = (
                    `${profile.kyc.kycRejectionComment}`
                  );
                } else {
                  showcase = (
                    '--'
                  );
                }
                return showcase;
              })()}
            </>
          ),
      },
      {
        id: 25,
        label: 'Overall KYC Updated By',
        value: profile.kyc.kycUpdatedBy,
      },
      {
        id: 26,
        label: 'Overall KYC Status',
        value: (this.kycStatus(onboardingStatus)),
      },
      {
        id: 27,
        label: 'Reason',
        value: (
          (onboardingStatus === 'DENIED' || onboardingStatus === 'WAITLIST') ? (
            this.kycRejectionReason()
          ) : (
            <>--</>
          )
        ),
      },
      {
        id: 28,
        label: 'Comment',
        value: (
          (onboardingStatus === 'DENIED' || onboardingStatus === 'WAITLIST' || onboardingStatus === 'APPROVED') ? (
            <input
              className="form-control"
              type="text"
              name="comment"
              placeholder="*Required if reason selects 'Others'"
              value={comment}
              onChange={(event) => {
                this.setState({
                  comment: event.target.value,
                });
              }}
              maxLength="250"
            />
          ) : (
            <>--</>
          )
        ),
      },
    ];

    const business = [
      {
        id: 1,
        label: 'Shop Name',
        value: profile.displayName,
      },
      {
        id: 2,
        label: 'Owner Name',
        value: profile.ownerName,
      },
      {
        id: 3,
        label: 'Shop Logo',
        value: (<img src={profile.shopLogo} alt="" width="100px" height="100px" />),
      },
      {
        id: 4,
        label: 'Shop Logo url',
        value: (<a href={profile.shopLogo} target="_blank" rel="noopener noreferrer">{profile.shopLogo}</a>),
      },
      {
        id: 5,
        label: 'Shop Category',
        value: profile.storeType.displayName,
      },
      {
        id: 6,
        label: 'Opening Time',
        value: profile.deliveryTiming.startTime,
      },
      {
        id: 7,
        label: 'Closing Time',
        value: profile.deliveryTiming.endTime,
      },
      {
        id: 8,
        label: '24 Hrs Delivery',
        value: profile.isDelivering24hrs ? 'ON' : 'OFF',
      },
      {
        id: 9,
        label: 'payment Method',
        value: `${cash?.isEnabled ? 'CASH,' : ''} ${digital?.isEnabled ? 'DIGITAL' : ''}`,
      },
      {
        id: 10,
        label: 'Phone Number',
        value: profile.phoneNumber,
      },
      {
        id: 11,
        label: 'Whatsapp Number',
        value: profile.whatsappNumber,
      },
      {
        id: 12,
        label: 'Shop Address',
        value: profile.completeAddress,
      },
      {
        id: 13,
        label: 'Landmark',
        value: profile.landmark,
      },
      {
        id: 14,
        label: 'Pin Code',
        value: profile.pinCode,
      },
      {
        id: 15,
        label: 'Latitude',
        value: profile.latitude,
      },
      {
        id: 16,
        label: 'Longitude',
        value: profile.longitude,
      },
      {
        id: 17,
        label: 'Shop Location',
        value: (
          <div
            className="text-truncate"
          >
            {(!profile.latitude && !profile.longitude) ? (
              <>NA</>
            ) : (
              <a
                href={`https://www.google.co.in/maps/search/${profile.latitude},${profile.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`https://www.google.co.in/maps/search/${profile.latitude},${profile.longitude}`}
              </a>
            )}
          </div>
        ),
      },
    ];

    const deliveryDetails = [
      {
        id: 1,
        label: 'Minimum Spend Amount',
        value: profile.homeDeliveryMinAmount,
      },
      {
        id: 2,
        label: 'Delivery Penalty',
        value: profile.minDeliveryPenalty,
      },
      {
        id: 3,
        label: 'Radius & Delivery Amount',
        value: (this.deliveryRadii(profile.deliveryRadii)),
      },
    ];

    const getApprovedBy = () => {
      if (bankAccountStatus === 'APPROVED') {
        if (profile.account && profile.account.autoApproved) {
          return 'SYSTEM';
        }
        return 'AGENT';
      }
      return 'NA';
    };

    const bankDetails = [
      {
        id: 1,
        label: 'Account Name',
        value: (
          <Row>
            {bankAccountStatus === 'RESET' && (
              <Col
                xs={2}
                className="pt-1"
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={accountName}
                  onChange={() => {
                    if (accountName) {
                      this.handleUncheckedItem(accountName, 'accountName');
                    } else {
                      this.handleCheckedItem(accountName, 'accountName');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.account.accountName}
            </Col>
          </Row>
        ),
      },
      {
        id: 2,
        label: 'Owner Name',
        value: profile.ownerName || '-- --',
      },
      {
        id: 3,
        label: 'Beneficiary Name',
        value: (
          <span
            className={`${
              profile.account.beneficiaryName && (
                profile.account.ownerNameMatch >= 80
                || profile.account.shopNameMatch >= 80)
                ? 'text-green' : 'text-danger'}`}
          >
            {profile.account.beneficiaryName || '-- --'}
          </span>
        ),
      },
      {
        id: 4,
        label: 'Account Name matching percentage',
        value: (
          <span
            className={`${profile.account.ownerNameMatch >= 80 ? 'text-green' : 'text-danger'}`}
          >
            {profile.account.ownerNameMatch || '-- --'}
          </span>
        ),
      },
      {
        id: 5,
        label: 'Account Number',
        value: (
          <Row>
            {bankAccountStatus === 'RESET' && (
              <Col
                xs={2}
                className="pt-1"
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={accountNumber}
                  onChange={() => {
                    if (accountNumber) {
                      this.handleUncheckedItem(accountNumber, 'accountNumber');
                    } else {
                      this.handleCheckedItem(accountNumber, 'accountNumber');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.account.accountNumber}
            </Col>
          </Row>
        ),
      },
      {
        id: 6,
        label: 'IFSC Code',
        value: (
          <Row>
            {bankAccountStatus === 'RESET' && (
              <Col
                xs={2}
                className="pt-1"
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={ifscCode}
                  onChange={() => {
                    if (ifscCode) {
                      this.handleUncheckedItem(ifscCode, 'ifscCode');
                    } else {
                      this.handleCheckedItem(ifscCode, 'ifscCode');
                    }
                  }}
                />
              </Col>
            )}
            <Col>
              {profile.account.ifscCode}
            </Col>
          </Row>
        ),
      },
      {
        id: 7,
        label: 'Shop name',
        value: profile.displayName || '-- --',
      },
      {
        id: 8,
        label: 'Shop name matching percentage',
        value: (
          <span
            className={`${profile.account.shopNameMatch >= 80 ? 'text-green' : 'text-danger'}`}
          >
            {profile.account.shopNameMatch || '-- --'}
          </span>
        ),
      },
      ...bankAccountStatus !== 'AUTO_VERIFICATION'
        ? [{
          id: 9,
          label: 'GST Number',
          value: (
            <Row>
              {bankAccountStatus === 'RESET' && !!profile.account.gstNumber
              && (
                <Col
                  xs={2}
                  className="pt-1"
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    checked={gstNumber}
                    onChange={() => {
                      if (gstNumber) {
                        this.handleUncheckedItem(gstNumber, 'gstNumber');
                      } else {
                        this.handleCheckedItem(gstNumber, 'gstNumber');
                      }
                    }}
                  />
                </Col>
              )}
              <Col>
                {profile.account.gstNumber}
              </Col>
            </Row>
          ),
        },
        {
          id: 10,
          label: 'Proof Type',
          value: profile.account.proofType,
        },
        {
          id: 11,
          label: 'Proof Type Image',
          value: (
            <Row>
              {bankAccountStatus === 'RESET' && (
              <Col
                xs={2}
                className="pt-4"
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={cancelledChequeUrl}
                  onChange={() => {
                    if (cancelledChequeUrl) {
                      this.handleUncheckedItem(cancelledChequeUrl, 'cancelledChequeUrl', 'proofType');
                    } else {
                      this.handleCheckedItem(cancelledChequeUrl, 'cancelledChequeUrl', 'proofType');
                    }
                  }}
                />
              </Col>
              )}
              <Col>
                <img src={profile.account.cancelledChequeUrl} alt="" width="100px" height="100px" />
              </Col>
            </Row>
          ),
        },
        {
          id: 12,
          label: 'Proof Image url',
          value: (<a href={profile.account.cancelledChequeUrl} target="_blank" rel="noopener noreferrer">{profile.account.cancelledChequeUrl}</a>),
        },
        {
          id: 13,
          label: 'Pan Holder Name',
          value: (
            <Row>
              {bankAccountStatus === 'RESET' && (
              <Col
                xs={2}
                className="pt-1"
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={panHolderName}
                  onChange={() => {
                    if (panHolderName) {
                      this.handleUncheckedItem(panHolderName, 'panHolderName');
                    } else {
                      this.handleCheckedItem(panHolderName, 'panHolderName');
                    }
                  }}
                />
              </Col>
              )}
              <Col>
                {profile.kyc.panHolderName}
              </Col>
            </Row>
          ),
        },
        {
          id: 14,
          label: 'Pan Number',
          value: profile.kyc.panNumber,
        },
        {
          id: 15,
          label: 'Pan Image',
          value: (
            <Row>
              {bankAccountStatus === 'RESET' && (
              <Col
                xs={2}
                className="pt-4"
              >
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={panImage}
                  onChange={() => {
                    if (panImage) {
                      this.handleUncheckedItem(panImage, 'panImage', 'panNumber');
                    } else {
                      this.handleCheckedItem(panImage, 'panImage', 'panNumber');
                    }
                  }}
                />
              </Col>
              )}
              <Col>
                <img src={profile.kyc.panImage} alt="" width="100px" height="100px" />
              </Col>
            </Row>
          ),
        },
        {
          id: 16,
          label: 'Pan Image url',
          value: (<a href={profile.kyc.panImage} target="_blank" rel="noopener noreferrer">{profile.kyc.panImage}</a>),
        }]
        : [],
      {
        id: 17,
        label: 'Bank Status Modify Reason',
        value:
          (profile.account.bankAccountStatus === 'DENIED' || profile.account.bankAccountStatus === 'WAITLIST')
            && (profile.account.BankRejectionReason || profile.account.bankRejectionComment)
            ? `${profile.account.bankRejectionReason}
              ${profile.account.bankRejectionComment ? `- ${profile.account.bankRejectionComment}` : ''}`
            : '--',
      },
      {
        id: 18,
        label: 'Last Status Updated By',
        value: profile.account.bankUpdatedBy,
      },
      {
        id: 19,
        label: 'Modify Status',
        value: (this.bankStatus(bankAccountStatus)),
      },
      {
        id: 20,
        label: 'Approved By',
        value: getApprovedBy(),
      },
      {
        id: 21,
        label: 'Reason',
        value: (
          (bankAccountStatus === 'DENIED' || bankAccountStatus === 'WAITLIST') ? (
            this.bankRejectionReason()
          ) : (
            <>--</>
          )
        ),
      },
      {
        id: 22,
        label: 'Comment',
        value: (
          (bankAccountStatus === 'DENIED' || bankAccountStatus === 'WAITLIST') ? (
            <input
              className="form-control"
              type="text"
              name="bankComment"
              placeholder="*Required if reason selects 'Others'"
              value={bankComment}
              onChange={(event) => {
                this.setState({
                  bankComment: event.target.value,
                });
              }}
              maxLength="250"
            />
          ) : (
            <>--</>
          )
        ),
      },
      {
        id: 23,
        label: 'Last Call Date',
        value: (
          lastCallDate
            ? <>{dateString(lastCallDate)}</>
            : '--'
        ),
      },
      {
        id: 24,
        label: 'Last Call Comment',
        value: (
          lastCallComment
            ? `${lastCallComment}`
            : '--'
        ),
      },
    ];

    const fundAccountDetails = [
      {
        id: 1,
        label: 'Fund Account Id',
        value: profile.fundAccount.id,
      },
      {
        id: 2,
        label: 'Status',
        value: (
          <span className={
            profile.fundAccount.status === 'FAILED'
              || profile.fundAccount.status === 'REJECTED'
              ? 'text-danger' : ''
          }
          >
            {profile.fundAccount.status}
          </span>
        ),
      },
      {
        id: 3,
        label: 'Description',
        value: profile.fundAccount.description,
      },
      {
        id: 4,
        label: 'Settlement Mode',
        value: profile.fundAccount.settlementMode,
      },
    ];

    const firstOrderDetails = [
      {
        id: 1,
        label: 'Last Status Updated By',
        value: profile.account.firstOrderStatusUpdatedBy,
      },
      {
        id: 2,
        label: 'Last Comment',
        value: profile.account.firstOrderVerificationComment || '--',
      },
      {
        id: 3,
        label: 'Modify Status',
        value: (this.firstOrderVerification(firstOrderStatus)),
      },
      {
        id: 4,
        label: 'Comment',
        value: (
          <input
            className="form-control"
            type="text"
            name="firstOrderComment"
            placeholder="Add Comment (Required)*"
            value={firstOrderComment}
            onChange={(event) => {
              this.setState({
                firstOrderComment: event.target.value,
              });
            }}
            disabled={profile.kyc.status !== 'COMPLETED' && profile.kyc.status !== 'APPROVED'}
            maxLength="250"
          />
        ),
      },
    ];

    switch (state) {
      case 'KYC':
        return (
          <OnboardingDetails
            {...this.props}
            title="KYC Details"
            id="KYC_DETAILS"
            tab="KYC_DETAILS"
            details={KYCDetail}
            showButton
            resetList={resetList}
            modifyStatus={modifyStatus}
            comment={comment}
            rejectionReason={reason}
            submitLoading={submitLoading}
            submitMessage={submitMessage}
            submitError={submitError}
            handleSubmit={this.handleKycStatus}
          />
        );
      case 'business':
        return (
          <OnboardingDetails
            {...this.props}
            title="Business Details"
            id="BUSINESS_DETAILS"
            tab="BUSINESS_DETAILS"
            details={business}
          />
        );
      case 'delivery':
        return (
          <OnboardingDetails
            {...this.props}
            title="Delivery Details"
            id="DELIVERY_DETAILS"
            tab="DELIVERY_DETAILS"
            details={deliveryDetails}
          />
        );
      case 'Bank':
        return (
          <OnboardingDetails
            {...this.props}
            title="Bank Details"
            id="BANK_DETAILS"
            tab="BANK_DETAILS"
            details={bankDetails}
            showButton
            resetList={resetList}
            modifyStatus={modifyStatus}
            bankAccountStatus={bankAccountStatus}
            comment={bankComment}
            upiComment={upiComment}
            accountDetails={profile.account}
            switchSettlementMode={() => this.switchMode('BANK')}
            rejectionReason={bankReason}
            submitLoading={activeTab === 'BANK' && (submitLoading || changingSettlementMode)}
            submitMessage={activeTab === 'BANK' ? submitMessage : ''}
            handleSubmit={this.handleBankStatus}
          />
        );
      case 'fundAccountDetails':
        return (
          <OnboardingDetails
            {...this.props}
            title="Fund Account Details"
            Iid="FUND_AC_DETAILS"
            tab="FUND_AC_DETAILS"
            details={fundAccountDetails}
            accountDetails={profile?.account}
            createFundAccount
            submitLoading={submitLoading}
            submitMessage={submitMessage}
            submitError={submitError}
            isDisabled={
              (bankAccountStatus !== 'APPROVED'
              && profile.account?.vpaDetails.crmStatus !== 'APPROVED')
              // || profile.fundAccount.status === 'ACTIVE'
              || !this.checkPermissions(
                [Permission.ONBOARDING_EXPLICIT_PERMISSION_ON_FUND_ACCOUNT_DETAILS,
                  Permission.RETAILER_WRITE],
              )
            }
            bankAccountStatus={bankAccountStatus !== 'APPROVED'}
            handleSubmit={this.handleFundAccount}
          />
        );
      case 'firstOrderStatus':
        return (
          <OnboardingDetails
            {...this.props}
            title="First Order Verification"
            id="FIRST_ORDER_STATUS"
            tab="FIRST_ORDER_STATUS"
            details={firstOrderDetails}
            showButton
            modifyStatus={modifyStatus}
            submitLoading={submitLoading}
            submitMessage={submitMessage}
            submitError={submitError}
            comment={firstOrderComment}
            isDisabled={
              profile.kyc.status !== 'COMPLETED' && profile.kyc.status !== 'APPROVED'
            }
            handleSubmit={this.handleFirstOrder}
          />
        );
      case 'retailerStory':
        return (
          <RetailerStoryForm
            title="Retailer Story"
            id="RETAILER_STORY"
            tab="RETAILER_STORY"
            editing
            approving={false}
            storeId={profile.code}
            userPermission={userPermission}
          />
        );
      default:
        return (<></>);
    }
  }

  checkPermissions = (permissions) => {
    const { userPermission } = this.props;
    return permissions.reduce((acc, item) => acc || userPermission.includes(item), false);
  }

  render() {
    const {
      loading, error, profile, selectedState,
      onboarding, testOrderInfo, retailerOwnStory,
      uploadDetails, serverMessage, showCallModal, showCommentHistory,
    } = this.state;
    const { userPermission } = this.props;
    if (loading || error) {
      return (
        <Col
          className="pt-3 text-center"
        >
          {
            (!profile && loading) ? (
              <Spinner
                variant="primary"
                animation="border"
              />
            ) : (
              <div
                className="text-danger"
              >
                Oops Something Went Wrong!!
              </div>
            )
          }
        </Col>
      );
    }

    const onboardingConf = [
      {
        id: 1,
        label: 'KYC Details',
        code: 'KYC',
        status: profile.kyc.status === 'COMPLETED' || profile.kyc.status === 'APPROVED',
        isDisable: false,
      },
      {
        id: 2,
        label: 'Business Details',
        code: 'business',
        status: profile.setupStages.businessDetail,
        isDisable: false,
      },
      {
        id: 3,
        label: 'Delivery Details',
        code: 'delivery',
        status: profile.setupStages.deliveryDetail,
        isDisable: false,
      },
      {
        id: 4,
        label: 'Settlement Details',
        code: 'Bank',
        status: profile.account.bankAccountStatus === 'APPROVED'
                || profile.account?.vpaDetails.crmStatus === 'APPROVED',
        isDisable: false,
      },
      {
        id: 5,
        label: 'Fund Account Details',
        code: 'fundAccountDetails',
        status: profile.fundAccount.status === 'ACTIVE',
        isDisable: false,
      },
      {
        id: 6,
        label: 'First Order Verification',
        code: 'firstOrderStatus',
        status: profile.account.firstOrderVerificationStatus === 'APPROVED',
        isDisable: false,
      },
      {
        id: 7,
        label: 'Retailer Story',
        code: 'retailerStory',
        status: (retailerOwnStory && retailerOwnStory.isCompleted) || false,
        isDisable: false,
      },
      {
        id: 8,
        label: 'Adding Products',
        code: 'product',
        status: profile.setupStages.productAdd,
        isDisable: true,
      },
      {
        id: 9,
        label: 'Adding Customers',
        code: 'customer',
        status: profile.setupStages.customerAdd,
        isDisable: true,
      },
    ];

    let testOrderInfoShowCase = null;
    switch (testOrderInfo) {
      case 'generating':
        testOrderInfoShowCase = (
          <div
            className="d-flex align-items-center text-center text-primary"
          >
            <Spinner
              animation="border"
              variant="primary"
            />
            &nbsp;Generating Test Order...
          </div>
        );
        break;

      case 'error':
        testOrderInfoShowCase = (
          <>
            <div
              className="text-danger"
            >
              Failed to generate test order automatically
            </div>
            <div
              className="pt-3 d-flex justify-content-end"
            >
              <Button
                variant="outline-primary"
                onClick={() => {
                  this.setState({
                    testOrderInfo: '',
                  });
                }}
              >
                DISMISS
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={() => {
                  this.createTestOrder();
                }}
              >
                RETRY
              </Button>
            </div>
          </>
        );
        break;

      case 'generated':
        testOrderInfoShowCase = (
          <>
            <div
              className="text-success"
            >
              Test order has been generated automatically for the retailer.
            </div>
            <Button
              variant="primary"
              className="m-3 float-right"
              onClick={() => {
                this.setState({
                  testOrderInfo: '',
                });
              }}
            >
              OKAY
            </Button>
          </>
        );
        break;

      default:
        break;
    }

    return (
      <Container
        fluid
        className="pb-6"
      >
        {
          !!testOrderInfo
          && (
            <CustomModal
              show
              body={(
                <div
                  className="p-4"
                >
                  {testOrderInfoShowCase}
                </div>
              )}
              onHide={() => {
                if (testOrderInfo !== 'generating') {
                  this.setState({
                    testOrderInfo: '',
                  });
                }
              }}
              autoSize
            />
          )
        }
        {
          showCallModal && (
            <BankDetailsVerificationCall
              phoneNumber={profile.phoneNumber}
              action="CALL_TO_RETAILER"
              show={showCallModal}
              onHide={() => {
                this.setState({
                  showCallModal: false,
                }, this.bankDetailsVerificationCall());
              }}
            />
          )
        }
        {
          showCommentHistory && (
            <ViewCommentsHistory
              show={showCommentHistory}
              onHide={() => this.setState({ showCommentHistory: false })}
              storeCode={profile.code}
            />
          )
        }
        <UploadKYC
          show={uploadDetails === 'KYC'}
          onHide={() => {
            this.toggleUploadDetails('');
          }}
          profile={profile}
          onUploadKyc={this.handleUploadDetails}
        />
        <UploadBankDetails
          show={uploadDetails === 'Bank'}
          onHide={() => {
            this.toggleUploadDetails('');
          }}
          profile={profile}
          handleUploadDetails={this.handleUploadDetails}
        />
        <Row>
          <Col
            xs={24}
            md={6}
          >
            <Row
              className="border mx-0 shadow bg-white border-radius-16 overflow-hidden"
            >
              {
                onboardingConf.map((item) => (
                  <Col
                    xs={24}
                    className="px-0"
                    key={item.id}
                  >
                    <Button
                      block
                      key={item.id}
                      variant={item.code === selectedState ? 'primary' : 'outline-primary'}
                      className={`rounded-0 border ${item.code === selectedState ? 'shadow' : ''}`}
                      disabled={item.isDisable}
                      onClick={() => {
                        this.setState({
                          selectedState: item.code,
                          resetList: [],
                          modifyStatus: '',
                          submitMessage: false,
                          submitError: '',
                        });
                      }}
                    >
                      <Row
                        className="justify-content-between align-items-center"
                      >
                        <Col
                          className="text-start"
                        >
                          {item.label}
                        </Col>
                        {item.status && (
                        <Col
                          xs="auto"
                        >
                          &#x2705;
                        </Col>
                        )}
                      </Row>
                    </Button>
                  </Col>
                ))
              }
            </Row>
          </Col>
          <Col
            xs={24}
            md={12}
            className="px-0"
          >
            {
              (selectedState === 'Bank')
              && Object.keys(profile?.account?.vpaDetails || {}).length !== 0
              && (
                <Row
                  className="shadow bg-white border border-radius-16 overflow-hidden p-0 m-0 mb-4 justify-content-center"
                >
                  {this.getUpiDetails(selectedState)}
                </Row>
              )
            }
            <Row
              className="shadow bg-white border border-radius-16 overflow-hidden p-0 m-0 justify-content-center"
            >
              {this.getDetails(selectedState)}
            </Row>
            {
              selectedState === 'Bank' && (
                <Row
                  className="p-0 m-0 justify-content-center"
                >
                  {this.verifyDetails(selectedState)}
                </Row>
              )
            }
          </Col>
          <Col
            xs={24}
            md={6}
            className=""
          >
            <Row
              className="border m-0 justify-content-center shadow bg-white py-3 border-radius-16"
            >
              <Col
                xs={24}
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  !profile.onHomeDelivery ? (
                    <Row>
                      <Col
                        xs={24}
                        className="text-center"
                      >
                        <b>Launch Shop Online</b>
                      </Col>
                      <Col
                        xs={24}
                        className="text-center pt-3"
                      >
                        {
                          (profile.kyc.status !== 'COMPLETED' || !profile.setupCompleted)
                            ? 'You Will be able to mark Shop online once Onboarding flow is completed.'
                            : 'Now You Can Launch Shop Online.'
                        }
                      </Col>
                      <Col
                        xs={24}
                        className="text-center pt-3"
                      >
                        <Button
                          variant="primary"
                          // className="d-flex align-items-center"
                          disabled={
                            !(profile.kyc.status === 'COMPLETED' || profile.kyc.status === 'APPROVED')
                            || !profile.setupStages.businessDetail
                            || !profile.setupStages.deliveryDetail
                            // || !profile.setupStages.bankDetail
                            || !profile.setupStages.productAdd
                            || onboarding
                            || !(this.checkPermissions([Permission.RETAILER_WRITE,
                              Permission.EXPLICIT_PERMISSION_ON_LAUNCH_SHOP_BUTTON]))
                          }
                          onClick={this.launchShopOnline}
                        >
                          {
                            onboarding ? (
                              <>
                                <Spinner
                                  animation="border"
                                  variant="white"
                                />
                                &nbsp;
                              </>
                            ) : ''
                          }
                          <span>Launch Shop</span>
                        </Button>
                        {!onboarding && serverMessage
                          && (
                            <Row className="text-center pt-3 mr-2">
                              <span
                                className="text-center text-danger"
                              >
                                <b>{serverMessage}</b>
                              </span>
                            </Row>
                          )}
                      </Col>
                    </Row>
                  ) : (
                    <div
                      className="text-center text-green"
                    >
                      <b>Shop is Onboarded</b>
                    </div>
                  )
                }
                {
                  (selectedState === 'KYC' || selectedState === 'Bank') && (
                    <Row
                      className="d-flex justify-content-center text-center pt-3"
                    >
                      <Button
                        onClick={() => {
                          this.toggleUploadDetails(selectedState);
                        }}
                        disabled={!(userPermission.includes(Permission.RETAILER_WRITE)
                          || userPermission.includes(
                            selectedState === 'KYC'
                              ? Permission.MODIFY_KYC_STATUS
                              : Permission.ONBOARDING_EXPLICIT_PERMISSION_ON_BANK_DETAIL_SECTION,
                          ))}
                      >
                        {`Update ${selectedState} Details`}
                      </Button>
                    </Row>
                  )
                }

                {
                  (selectedState === 'KYC' || selectedState === 'Bank') && (
                    <Row
                      className="d-flex justify-content-center text-center pt-3"
                    >
                      <Button
                        onClick={() => {
                          this.setState({
                            showCommentHistory: true,
                          });
                        }}
                      >
                        View Comment History
                      </Button>
                    </Row>
                  )
                }
                {
                  (selectedState === 'Bank') && (
                    <Row
                      className="d-flex justify-content-center text-center pt-3"
                    >
                      <Button
                        onClick={() => {
                          this.setState({
                            showCallModal: true,
                          });
                        }}
                        disabled={!(userPermission.includes(Permission.RETAILER_WRITE)
                          || userPermission.includes(
                            Permission.ONBOARDING_EXPLICIT_PERMISSION_ON_BANK_DETAIL_SECTION,
                          )
                        )}
                      >
                        Call to Retailer
                      </Button>
                    </Row>
                  )
                }
              </Col>
            </Row>

            {
              selectedState === 'KYC' && (
              <Row
                className="border m-0 shadow bg-white mt-3 py-3 border-radius-16"
              >
                <Col
                  xs={24}
                >
                  <KYCVerification
                    profile={profile}
                    setProfileDetails={this.getStoreProfile}
                  />
                </Col>
              </Row>
              )
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

OnboardingStates.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default OnboardingStates;
