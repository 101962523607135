import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  adminCuratedLibraryGdroupMap, storeCategory, registeredRegion,
} from '../../assets/api/axios';
import {
  CustomTable, CustomModal, CustomDropdown, Svg,
} from '../../component/common';
import { Constant } from '../../utilities';
import { dateString, getHoursDiff } from '../../utilities/Utils';
import {
  getRegesteredRegions, getStoreCategory, setRegesteredRegions,
  setStoreCategory,
} from '../../utilities/Storage';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    groupName = '',
    isEnabled = '',
    isDefault = '',
    sizeOfStore = '12',
    regionIn = '',
    storeTypeIds = '',
    curatedProductGroupId = '',
    libraryProductId = '',
    showPerformingActionModal = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
    groupName,
    isEnabled,
    isDefault,
    sizeOfStore,
    regionIn,
    storeTypeIds,
    curatedProductGroupId,
    libraryProductId,
    showPerformingActionModal: showPerformingActionModal === 'true',
  });
};

class SmartCatalogueMapping extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let storeCategories = getStoreCategory();
    if (storeCategories) {
      const hrDiff = getHoursDiff(storeCategories.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        storeCategories = null;
      }
    }
    let regions = getRegesteredRegions();
    if (regions) {
      const hrDiff = getHoursDiff(regions.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        regions = null;
      }
    }
    const { userPermission } = this.props;
    this.permissions = {
      ratroactive: userPermission.includes(permission.SMART_CATALOGUE_MAP_WRITE_RETROACTIVE),
      write: userPermission.includes(permission.SMART_CATALOGUE_MAP_WRITE),
    };
    this.state = {
      storeCategories: (storeCategories && storeCategories.categories) || null,
      regions: (regions && regions.regions) || null,
      curatedGroupMappings: [],
      curatedGroupMappingsCount: 0,
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      submitting: false,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
      param,
      newParams: param,
      viewCuratedGroupMapMetaModal: false,
      curatedGroupMapMetaData: null,
      curatedGroupMapMetaDataLoadStatus: '',
      selectedCuratedGrMapToPerformAction: null,
      showPerformingActionModal: false,
      action: '',
      syncType: 'IGNORE',
      reason: '',
      isEnabled: false,
      isDefault: true,
      sizeOfStore: '',
      region: '',
      storeTypeId: '',
      CuratedProductGroupId: '',
      productsSyncType: 'IGNORE',
      showDeleteCuratedGroupInfo: null,
      deleteCuratedGroupStatus: '',
      deleteBackendRespStatus: '',
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
    const { param } = this.state;
    if (param.showPerformingActionModal) {
      this.setState({
        showPerformingActionModal: true,
        action: 'ADD_CURATED_GROUP_MAP',
        CuratedProductGroupId: param.curatedProductGroupId,
      });
    }
    const { storeCategories, regions } = this.state;
    if (!storeCategories) {
      storeCategory('GET').then((res) => {
        this.setState({
          storeCategories: res.data.results,
        });
        setStoreCategory({
          categories: res.data.results,
          lastUpdatedTime: new Date(),
        });
      }).catch(() => {});
    }
    if (!regions) {
      registeredRegion('GET').then((res) => {
        this.setState({ regions: res.data.results });
        setRegesteredRegions({
          regions: res.data.results,
          lastUpdatedTime: new Date(),
        });
      }).catch(() => {});
    }
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  applyFilters = () => {
    const { newParams } = this.state;
    this.handleRequestProcessing({
      ...newParams,
      p: 1,
    });
  }

  resetfilters = () => {
    this.handleRequestProcessing({
      groupName: '',
      isEnabled: '',
      isDefault: '',
      sizeOfStore: '12',
      regionIn: '',
      storeTypeIds: '',
      p: 1,
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParams: { ...state.newParams, ...data },
    }));
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      newParams: { ...state.newParams, [name]: value },
    }));
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, groupName, isEnabled, isDefault, storeTypeIds, regionIn, sizeOfStore,
      curatedProductGroupId, libraryProductId,
    } = newParam;
    const offset = (p - 1) * l;
    this.setState({
      curatedGroupMappings: [],
    });
    adminCuratedLibraryGdroupMap(
      'GET',
      '',
      {
        view: 'DEFAULT',
        offset: `${offset}`,
        limit: l,
        searchText: groupName,
        isEnabled,
        onlyRegionalDefault: isDefault,
        sizeOfStoreList: sizeOfStore,
        storeTypeIds,
        regionIn,
        curatedProductGroupId,
        libraryProductId,
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        curatedGroupMappings: res.data.results,
        loading: false,
        curatedGroupMappingsCount: res.data.count,
        hasNext: res.data.hasNext,
        hasPrevious: res.data.hasPrevious,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        newParams: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  getPayloadToPerformAction = (action) => {
    const {
      reason, selectedCuratedGrMapToPerformAction, isEnabled, isDefault,
      syncType, sizeOfStore, region, storeTypeId, CuratedProductGroupId,
      productsSyncType,
    } = this.state;
    switch (action) {
      case 'UPDATE_ENABLED_STATE':
        return ({
          action,
          body: { id: selectedCuratedGrMapToPerformAction.groupMapId, reason, isEnabled },
          syncJobTriggerAt: syncType,
        });
      case 'ARCHIVE_CURATED_GROUP_MAP':
        return ({
          action,
          body: { id: selectedCuratedGrMapToPerformAction.groupMapId, reason },
          syncJobTriggerAt: syncType,
        });
      case 'ADD_CURATED_GROUP_MAP':
        return ({
          action,
          body: {
            sizeOfStore, region, storeTypeId, CuratedProductGroupId, isDefault, productsSyncType,
          },
          syncJobTriggerAt: syncType,
        });
      case 'MARK_AS_REGIONAL_DEFAULT':
        return ({
          action,
          body: {
            id: selectedCuratedGrMapToPerformAction.groupMapId,
            reason,
          },
          syncJobTriggerAt: syncType,
        });
      default:
        return ({
          action,
          body: {
            id: selectedCuratedGrMapToPerformAction.groupMapId,
            reason,
          },
          syncJobTriggerAt: syncType,
        });
    }
  }

  performAction = () => {
    this.setState({
      submitting: true,
      submitError: false,
      submitErrorMsg: '',
      actionSuccessMsg: false,
    });
    const {
      selectedCuratedGrMapToPerformAction, action,
    } = this.state;
    const body = this.getPayloadToPerformAction(action);
    adminCuratedLibraryGdroupMap(
      'POST',
      '',
      {},
      body,
      this.source.token,
    ).then((res) => {
      const { curatedGroupMappings: newCuratedGroupMappings } = this.state;
      let { curatedGroupMappingsCount: newCuratedGroupMappingsCount } = this.state;
      if (action === 'ADD_CURATED_GROUP_MAP') {
        newCuratedGroupMappings.splice(0, 0, res.data);
        newCuratedGroupMappingsCount += 1;
      } else {
        const index = newCuratedGroupMappings.findIndex((item) => (
          item.groupMapId === selectedCuratedGrMapToPerformAction.groupMapId
        ));
        if (action === 'ARCHIVE_CURATED_GROUP_MAP') {
          newCuratedGroupMappings.splice(index, 1);
          newCuratedGroupMappingsCount -= 1;
        } else {
          newCuratedGroupMappings[index] = {
            ...newCuratedGroupMappings[index],
            ...res.data,
          };
        }
      }
      if (res.status === 200) {
        this.setState({
          submitting: false,
          curatedGroupMappings: newCuratedGroupMappings,
          curatedGroupMappingsCount: newCuratedGroupMappingsCount,
          selectedCuratedGrMapToPerformAction: null,
          showPerformingActionModal: false,
          syncType: 'IGNORE',
          sizeOfStore: '',
          region: '',
          storeTypeId: '',
          CuratedProductGroupId: '',
          productsSyncType: 'IGNORE',
          reason: '',
          isEnabled: false,
          isDefault: true,
          action: '',
          actionSuccessMsg: true,
        }, () => {
          this.handleRequestProcessing();
          setTimeout(() => {
            this.setState({
              actionSuccessMsg: false,
            });
          }, 5000);
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  loadCuratedGroupMapMeta = (curatedgroupMapId) => {
    adminCuratedLibraryGdroupMap(
      'GET',
      curatedgroupMapId,
      {
        view: 'META',
      },
      null,
      this.source.token,
    ).then((res) => {
      this.setState({
        curatedGroupMapMetaDataLoadStatus: '',
        curatedGroupMapMetaData: res.data.results,
      });
    }).catch(() => {
      this.setState({
        curatedGroupMapMetaDataLoadStatus: 'error',
      });
    });
    this.retry = () => {
      this.setState({
        curatedGroupMapMetaDataLoadStatus: 'loading',
      }, () => {
        this.loadCuratedGroupMapMeta(curatedgroupMapId);
      });
    };
  }

  getTitleForAction = (action) => {
    switch (action) {
      case 'UPDATE_ENABLED_STATE':
        return 'Altering Curated Group Enable Status';
      case 'ADD_CURATED_GROUP_MAP':
        return 'Add New Curated Group Map';
      case 'ARCHIVE_CURATED_GROUP_MAP':
        return 'Archive Curated Group';
      case 'UPDATE_LIBRARY_PRODUCTS':
        return 'Update Library Product Ids';
      case 'MARK_AS_REGIONAL_DEFAULT':
        return 'Mark as Regional Default';
      case 'RUN_SYNC_ON_ALL_STORE':
        return 'Run Sync on all Stores';
      case 'DELETE_CURATED_GROUP':
        return 'Delete Curated Group';
      default:
        return `Performing action - ${action}`;
    }
  }

  getGroupStatus = (modalActionableData = {}, isAlert = false) => {
    const {
      selectedCuratedGrMapToPerformAction, action, deleteBackendRespStatus,
    } = modalActionableData;
    this.setState({
      showDeleteCuratedGroupInfo: modalActionableData,
      action,
      selectedCuratedGrMapToPerformAction,
      deleteBackendRespStatus,
      deleteCuratedGroupStatus: 'LOADING',
    }, () => {
      const body = {
        action: 'DELETE_CURATED_GROUP',
        body: {
          id: selectedCuratedGrMapToPerformAction.groupMapId,
          isAlert,
        },
        syncJobTriggerAt: 'NOW',
      };
      adminCuratedLibraryGdroupMap(
        'POST',
        '',
        {},
        body,
        this.source.token,
      ).then((res) => {
        this.setState({
          deleteBackendRespStatus: res.data.status,
          deleteCuratedGroupStatus: isAlert ? 'FIRST_SUCCESS' : 'FINAL_SUCCESS',
        }, () => {
          if (!isAlert) {
            setTimeout(() => {
              this.closeDeleteCuratedAlert();
              window.location.reload();
            }, 1000);
          }
        });
      }).catch(() => {
        this.setState({
          deleteCuratedGroupStatus: 'ERROR',
        });
      });
    });
  }

  closeDeleteCuratedAlert = () => {
    this.setState({
      showDeleteCuratedGroupInfo: null,
      deleteCuratedGroupStatus: '',
      deleteBackendRespStatus: '',
      action: '',
      selectedCuratedGrMapToPerformAction: null,
    });
  }

  render() {
    const {
      param, loading, error, curatedGroupMappings, isDefault,
      rowsPerPage, page, submitting, submitError, curatedGroupMappingsCount,
      hasNext, hasPrevious, curatedGroupMapMetaData, viewCuratedGroupMapMetaModal,
      curatedGroupMapMetaDataLoadStatus, actionSuccessMsg,
      action, showPerformingActionModal, selectedCuratedGrMapToPerformAction,
      syncType, reason, isEnabled, submitErrorMsg, sizeOfStore, region, storeTypeId,
      CuratedProductGroupId, productsSyncType, storeCategories, regions, newParams,
      showDeleteCuratedGroupInfo, deleteCuratedGroupStatus, deleteBackendRespStatus,
    } = this.state;

    const { history } = this.props;

    const headers = [
      {
        key: 'groupMapId',
        displayText: 'Group Map Id',
      },
      {
        key: 'groupName',
        displayText: 'Group Name',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              history.push(`/smart-catalogues?curatedProductGroupMapId=${data.groupMapId}`);
            }}
            className="p-0 fs-01 d-block"
          >
            {data.groupName}
          </Button>
        ),
      },
      {
        key: 'region',
        displayText: 'Region',
      },
      {
        key: 'sizeOfStore',
        displayText: 'Size Of Stores',
      },
      {
        key: 'storeTypeName',
        displayText: 'Store Type Name',
      },
      {
        key: 'storeTypeId',
        displayText: 'Store Type Id',
      },
      {
        key: 'previousJobDetail',
        displayText: 'Prev. Job Detail (Clickable Links)',
        renderer: (data) => (
          <>
            {
              (data.previousJobDetail).map((item) => (
                <div key={item.text}>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pb-2"
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.text}
                  </a>
                </div>
              ))
            }
          </>
        ),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'groupIsEnabled',
        displayText: 'Is Enabled',
        renderer: (data) => (
          <div
            className={data.groupIsEnabled ? 'text-green' : 'text-danger'}
          >
            {data.groupIsEnabled ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'isRegionalDefault',
        displayText: 'Is Regional Default',
        renderer: (data) => (
          <div
            className={data.isRegionalDefault ? 'text-green' : 'text-danger'}
          >
            {data.isRegionalDefault ? 'YES' : 'NO'}
          </div>
        ),
      },
      {
        key: 'meta',
        displayText: 'View Meta',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                curatedGroupMapMetaData: null,
                viewCuratedGroupMapMetaModal: true,
                curatedGroupMapMetaDataLoadStatus: 'loading',
              }, this.loadCuratedGroupMapMeta(data.groupMapId));
            }}
            className="fs-01 p-0"
          >
            META
          </Button>
        ),
      },
      ...this.permissions
      && (
        this.permissions.ratroactive
        || this.permissions.write
      ) ? [
          {
            key: 'other-action',
            displayText: 'Other Action',
            renderer: (data) => (
              <select
                className="form-control fs-01 minw-120p"
                onChange={(event) => {
                  if (!event.target.value) {
                    return;
                  }
                  const modalActionableData = {
                    selectedCuratedGrMapToPerformAction: data,
                    showPerformingActionModal: true,
                    action: event.target.value,
                    isEnabled: data.groupIsEnabled,
                  };
                  if (event.target.value === 'DELETE_CURATED_GROUP') {
                    this.getGroupStatus(
                      {
                        ...modalActionableData,
                        deleteBackendRespStatus: '',
                      },
                      true,
                    );
                    return;
                  }
                  this.setState({
                    ...modalActionableData,
                  });
                }}
                value={
                  selectedCuratedGrMapToPerformAction
                  && selectedCuratedGrMapToPerformAction.groupMapId === data.groupMapId
                    ? action : ''
                }
              >
                <option value="">--SELECT ACTION--</option>
                {
                  data.allowedActions.map((item) => (
                    <option value={item} key={item}>{item}</option>
                  ))
                }
              </select>
            ),
          },
        ] : [],
      {
        key: 'lastSync',
        displayText: 'Last Sync Status',
      },
    ];

    const filterConfForSingleSelect = [
      {
        key: 'isEnabled',
        displayText: 'Enabled Status',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isDefault',
        displayText: 'Is Default',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'sizeOfStore',
        displayText: 'Size Of Store',
        options: [
          {
            label: '1',
            value: '1',
          },
          {
            label: '1, 2',
            value: '12',
          },
          {
            label: '1, 2, 3',
            value: '123',
          },
        ],
      },
      {
        key: 'storeTypeIds',
        displayText: 'Store Type',
        options: ((storeCategories || []).map((item) => ({
          label: item.description,
          value: (item.id).toString(),
        }))),
      },
      {
        key: 'regionIn',
        displayText: 'Region',
        options: ((regions || []).map((item) => ({
          label: item.region,
          value: item.region,
        }))),
      },
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={!!deleteCuratedGroupStatus}
          onHide={() => {
            if (deleteCuratedGroupStatus !== 'LOADING') {
              this.closeDeleteCuratedAlert();
            }
          }}
          title={this.getTitleForAction(action)}
          closeButton
          autoSize
          size="md"
          body={(
            <Container
              className="py-3"
            >
              {(() => {
                let deleteActionJSX = null;
                switch (deleteCuratedGroupStatus) {
                  case 'LOADING':
                    deleteActionJSX = (
                      <Row
                        className="justify-content-center"
                      >
                        <Spinner
                          animation="border"
                          variant="primary"
                        />
                      </Row>
                    );
                    break;

                  case 'ERROR':
                    deleteActionJSX = (
                      <Row>
                        <Col
                          xs={24}
                          className="text-danger text-center"
                        >
                          Oops, Something went wrong. Please Try Again!
                        </Col>
                        <Col
                          xs={24}
                          className="d-flex justify-content-center pt-3"
                        >
                          <Button
                            variant="primary"
                            onClick={() => {
                              this.getGroupStatus(showDeleteCuratedGroupInfo, true);
                            }}
                          >
                            RETRY
                          </Button>
                        </Col>
                      </Row>
                    );
                    break;

                  case 'FIRST_SUCCESS':
                  case 'FINAL_SUCCESS':
                    deleteActionJSX = (
                      <Row>
                        <Col
                          xs={24}
                          className="text-center"
                        >
                          {deleteBackendRespStatus}
                        </Col>
                        {
                          deleteCuratedGroupStatus === 'FIRST_SUCCESS' && (
                            <Col
                              xs={24}
                              className="d-flex justify-content-end pt-3"
                            >
                              <Button
                                variant="danger"
                                onClick={() => {
                                  this.getGroupStatus(showDeleteCuratedGroupInfo, false);
                                }}
                              >
                                DELETE
                              </Button>
                              <Button
                                variant="primary"
                                className="ml-3"
                                onClick={() => {
                                  this.closeDeleteCuratedAlert();
                                }}
                              >
                                CLOSE
                              </Button>
                            </Col>
                          )
                        }
                      </Row>
                    );
                    break;
                  default:
                    break;
                }
                return deleteActionJSX;
              })()}
            </Container>
          )}
        />
        <CustomModal
          show={viewCuratedGroupMapMetaModal}
          onHide={() => {
            this.setState({
              viewCuratedGroupMapMetaModal: false,
              curatedGroupMapMetaData: null,
            });
          }}
          title="Curated Group Meta Data"
          closeButton
          size="lg"
          body={(
            <Container>
              {
                curatedGroupMapMetaDataLoadStatus === 'loading' && (
                  <div
                    className="pt-3 text-center"
                  >
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )
              }
              {
                curatedGroupMapMetaDataLoadStatus === 'error' && (
                  <div
                    className="pt-3 text-center text-danger"
                  >
                    Something Went Wrong
                    <Button
                      variant="primary"
                      onClick={() => this.retry()}
                      className="ml-3"
                    >
                      RETRY
                    </Button>
                  </div>
                )
              }
              {
                curatedGroupMapMetaData && (curatedGroupMapMetaData).map((item) => (
                  <Row key={item.key}>
                    <Col xs={8} className="border py-1">
                      {item.key}
                    </Col>
                    <Col xs={8} className="border py-1">
                      {item.value}
                    </Col>
                    <Col xs={8} className="border py-1 text-truncate-2">
                      {
                        item.href
                          ? (
                            <a
                              href={item.href || ''}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: item.color,
                              }}
                            >
                              {item.href}
                              <span className="pl-2">&#8599;</span>
                            </a>
                          ) : (
                            <span>NA</span>
                          )
                      }
                    </Col>
                  </Row>
                ))
              }
            </Container>
          )}
        />
        <CustomModal
          show={showPerformingActionModal}
          title={this.getTitleForAction(action)}
          onHide={() => {
            this.setState({
              showPerformingActionModal: false,
              submitError: false,
              syncType: 'IGNORE',
              sizeOfStore: '',
              region: '',
              storeTypeId: '',
              CuratedProductGroupId: '',
              productsSyncType: 'IGNORE',
              reason: '',
              isEnabled: false,
              isDefault: true,
              action: '',
            });
          }}
          size="lg"
          autoSize
          closeButton
          body={(
            <Container
              className="py-3"
            >
              {
                selectedCuratedGrMapToPerformAction
                && (action === 'UPDATE_ENABLED_STATE')
                && (
                  <>
                    <Row>
                      <Col xs={8}>
                        <b>Group Id:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        {selectedCuratedGrMapToPerformAction.groupMapId}
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col xs={8}>
                        <b>Group Name:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3 text-truncate"
                      >
                        {selectedCuratedGrMapToPerformAction.groupName}
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Enabled:</b>
                      </Col>
                      <Col
                        xs={4}
                        className="px-3"
                      >
                        <Form.Check
                          inline
                          label="Yes"
                          name="isEnabled"
                          type="radio"
                          checked={isEnabled}
                          onChange={this.handleRadioButtonChange}
                        />
                      </Col>
                      <Col
                        xs={4}
                        className="px-3"
                      >
                        <Form.Check
                          inline
                          label="No"
                          name="isEnabled"
                          type="radio"
                          checked={!isEnabled}
                          onChange={this.handleRadioButtonChange}
                        />
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                action === 'ADD_CURATED_GROUP_MAP' && (
                  <>
                    <Row>
                      <Col
                        xs={8}
                      >
                        <b>Size Of Store</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="sizeOfStore"
                          id="sizeOfStore"
                          className="form-control"
                          value={sizeOfStore}
                          onChange={this.handleChange}
                        >
                          <option value="">--Select Size Of Store--</option>
                          <option value="1">1</option>
                          <option value="12">1, 2</option>
                          <option value="123">1, 2, 3</option>
                        </select>
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Region:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="region"
                          id="region"
                          className="form-control"
                          value={region}
                          onChange={this.handleChange}
                        >
                          <option value="">--Select Region--</option>
                          {
                            (regions || []).map((item) => (
                              <option value={item.region} key={item.region}>{item.region}</option>
                            ))
                          }
                        </select>
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Store Type Id:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="storeTypeId"
                          id="storeTypeId"
                          className="form-control"
                          value={storeTypeId}
                          onChange={this.handleChange}
                        >
                          <option value="">--Select Store Type--</option>
                          {
                            (storeCategories || []).map((item) => (
                              <option value={item.id} key={item.id}>{item.description}</option>
                            ))
                          }
                        </select>
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Curated Product Group Id:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        <input
                          type="number"
                          className="form-control"
                          name="CuratedProductGroupId"
                          value={CuratedProductGroupId}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Set as Default:</b>
                      </Col>
                      <Col
                        xs={4}
                        className="px-3"
                      >
                        <Form.Check
                          inline
                          label="Yes"
                          name="isDefault"
                          type="radio"
                          checked={isDefault}
                          onChange={this.handleRadioButtonChange}
                        />
                      </Col>
                      <Col
                        xs={4}
                        className="px-3"
                      >
                        <Form.Check
                          inline
                          label="No"
                          name="isDefault"
                          type="radio"
                          checked={!isDefault}
                          onChange={this.handleRadioButtonChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Products Sync Type:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="productsSyncType"
                          id="productsSyncType"
                          className="form-control"
                          value={productsSyncType}
                          onChange={(event) => {
                            const { name, value } = event.target;
                            this.setState({
                              [name]: value,
                              ...value === 'IGNORE' ? { syncType: 'IGNORE' } : {},
                            });
                          }}
                        >
                          <option value="">--Select Products Sync Type--</option>
                          <option value="IGNORE">IGNORE</option>
                          <option value="APPEND">APPEND</option>
                        </select>
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                <Row className="py-2">
                  <Col
                    xs={8}
                  >
                    <b>Sync Trigger At:</b>
                  </Col>
                  <Col
                    className="px-3"
                    xs={16}
                  >
                    <select
                      name="syncType"
                      id="syncType"
                      className="form-control"
                      value={syncType}
                      onChange={this.handleChange}
                    >
                      <option value="IGNORE">IGNORE</option>
                      {
                          (action !== 'ADD_CURATED_GROUP_MAP'
                          || productsSyncType !== 'IGNORE')
                          && this.permissions
                          && this.permissions.ratroactive
                          && (
                            <>
                              <option value="NOW">NOW</option>
                              <option value="NIGHT">NIGHT</option>
                            </>
                          )
                        }
                    </select>
                  </Col>
                </Row>
              }
              {
                (
                  action !== 'ADD_CURATED_GROUP_MAP'
                )
                && (
                  <Row
                    className="pt-3"
                  >
                    <Col
                      xs={8}
                    >
                      <b>Reason:</b>
                    </Col>
                    <Col
                      xs={16}
                      className="px-3"
                    >
                      <textarea
                        rows="3"
                        className="form-control"
                        name="reason"
                        value={reason}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                )
              }
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-5"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2"
                    disabled={
                      submitting
                      || (action === 'ADD_CURATED_GROUP_MAP'
                          && (!sizeOfStore || !region || !storeTypeId || !CuratedProductGroupId))
                      || (action !== 'ADD_CURATED_GROUP_MAP'
                          && !reason)
                      || (action === 'UPDATE_ENABLED_STATE'
                          && selectedCuratedGrMapToPerformAction.groupIsEnabled === isEnabled)
                    }
                    onClick={this.performAction}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
                {submitError && (
                  <Col
                    xs={24}
                    className="text-center text-danger pt-3"
                  >
                    <b>
                      {
                        submitErrorMsg || 'Oops Something went Wrong!!'
                      }
                    </b>
                  </Col>
                )}
              </Row>
            </Container>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center py-2"
            >
              <Col
                xs="auto"
                className="px-2 pt-2"
              >
                <div className="pb-1 fs-01 text-disabled">
                  Search Group Name:
                </div>
                <div>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        className="rounded-0"
                      >
                        <Svg
                          svg="search"
                          width="1rem"
                          fill={Constant.Color.DARK}
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Group Name"
                      name="groupName"
                      className="fs-01 rounded-0"
                      value={newParams.groupName}
                      onChange={this.handleOnChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                </div>
              </Col>
              {
                filterConfForSingleSelect.map((item) => (
                  <Col
                    key={item.key}
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <div className="pb-1 fs-01 text-disabled">
                      {`Select ${item.displayText}:`}
                    </div>
                    <div>
                      <CustomDropdown
                        item={item}
                        onChange={(data) => {
                          this.handleDropdownChange(data);
                        }}
                        selectedVal={newParams[item.key]}
                      />
                    </div>
                  </Col>
                ))
              }
              {
                filterConfForMultiSelect.map((item) => (
                  <Col
                    key={item.key}
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <div className="pb-1 fs-01 text-disabled">
                      {`Select ${item.displayText}:`}
                    </div>
                    <div>
                      <Select
                        id={item.key}
                        placeholder={item.displayText}
                        onChange={(selectedItems) => {
                          const selectedItemsString = selectedItems.reduce(
                            (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                          );
                          this.handleDropdownChange({ [item.key]: selectedItemsString });
                        }}
                        selectedVal={newParams[item.key]}
                        options={item.options}
                        isMulti
                        value={newParams[item.key]
                          ? (newParams[item.key].split(','))
                            .map((val) => ({ label: val, value: val }))
                          : []}
                        className="minw-150p"
                      />
                    </div>
                  </Col>
                ))
              }
            </Row>
            <Row className="py-2 d-flex align-items-center flex-row-reverse">
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold"
                  onClick={this.resetfilters}
                >
                  RESET FILTERS
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="success"
                  className="fs-01 font-weight-bold"
                  onClick={this.applyFilters}
                >
                  APPLY FILTERS
                </Button>
              </Col>
              {
                actionSuccessMsg && (
                  <Col
                    xs="auto"
                    className="px-2 mr-auto"
                  >
                    <div className="text-success">
                      Action Performed SuccessFully!!
                    </div>
                  </Col>
                )
              }
              {
                this.permissions
                && (this.permissions.ratroactive
                || this.permissions.write)
                && (
                  <Col
                    xs="auto"
                    className="px-2 mr-auto"
                  >
                    <Button
                      variant="primary"
                      className="fs-01 font-weight-bold"
                      onClick={() => {
                        this.setState({
                          showPerformingActionModal: true,
                          action: 'ADD_CURATED_GROUP_MAP',
                          groupName: '',
                          syncType: 'IGNORE',
                        });
                      }}
                    >
                      ADD NEW GROUP MAP
                    </Button>
                  </Col>
                )
              }
            </Row>
          </Col>
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        RETRY
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            (!loading && !error) && (
              <Col
                className="pt-4 px-0 flex-grow-1 overflow-y"
              >
                <CustomTable
                  headers={headers}
                  content={curatedGroupMappings}
                  keyField="groupMapId"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={curatedGroupMappingsCount}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

SmartCatalogueMapping.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default SmartCatalogueMapping;
